import React, { useEffect } from 'react';
import { 
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import './App.css';
import ConsultationRoom from './components/ConsultationRoom'
import LandingPage from './components/LandingPage'
import OtpPage from './components/OtpPage';
import Dashboard from './components/Dashboard';
import dataManager from './utils/DataManager';
import constants from './constants';
import { Typography } from '@material-ui/core';
import sentryManager from './utils/SentryManager';
import Footer from './components/Footer';
import FaqPage from './components/FaqPage'
import TncPage from './components/TncPage'
import UploadReportPage from './components/UploadReportPage'
import DeviceTestStandalone from './components/DeviceTestStandalone';
import PrivateRoute from './components/PrivateRoute';
import useReplaceState from './hooks/useReplaceState';
import DoctorsListingPage from './components/DoctorsListingPage';
import AppointmentsPage from './components/AppointmentsPage';
import ConfirmSlot from './components/ConfirmSlot';
import ConfirmUserDetailsPage from './components/ConfirmUserDetailsPage';
import FeeReceipt from './components/FeeReceipt';

function App() {

  const paths = dataManager.getPaths();
  const location = useLocation();
  const noFooterPages = dataManager.getNoFooterPages();

  useReplaceState();

  useEffect(() => {
    document.body.style.height = window.innerHeight + 'px';
  }, [])

  useEffect(()=> {
    let authToken = dataManager.getFromLocalStorage('authToken')
    if (authToken) {
      authToken = JSON.parse(authToken)
      if (new Date(authToken.validity) > new Date()) {
        sentryManager.setTag('token', authToken.token);
        return;
      }
    }
  }, [])

  const publicRoutes = [
    {
      path: paths.login,
      component: LandingPage
    },
    {
      path: paths.faq,
      component: FaqPage
    },
    {
      path: paths.tnc,
      component: TncPage
    },
    {
      path: paths.receipt,
      component: FeeReceipt
    },
  ]

  const privateRoutes = [
    {
      path: paths.otp,
      component: OtpPage
    },
    {
      path: [paths.home, paths.paymentStatus, paths.doctorListing,
        paths.confirmSlot, paths.confirmUserDetails,
        paths.doctorListingSpecific
      ],
      component: Dashboard,
      routes: [
        {
          path: [paths.home, paths.paymentStatus],
          component: AppointmentsPage
        },
        {
          path: [paths.doctorListing, paths.doctorListingSpecific],
          component: DoctorsListingPage
        },
        {
          path: paths.confirmSlot,
          component: ConfirmSlot,
        },
        {
          path: paths.confirmUserDetails,
          component: ConfirmUserDetailsPage
        },
      ]
    },
    {
      path: paths.uploadReport,
      component: UploadReportPage
    },
    {
      path: paths.room,
      component: ConsultationRoom
    },
    {
      path: paths.deviceTest,
      component: DeviceTestStandalone
    },
  ]

  if (window.location.protocol.toLowerCase() === 'http:') {
    window.location =
      'https://' + window.location.host + constants.appRootUrl
    return (
      <div className={'land-padding http-redirector'}>
        <Typography variant="h2" component="h2" color="inherit" gutterbottom>
          Doxper
        </Typography>
        <Typography variant="h4" component="h4" color="inherit">
          Redirecting to HTTPs site...
        </Typography>
      </div>
    )
  }

  return (
    <>
      <Switch>
        {
          publicRoutes.map(route => {
            return (
              <Route exact
                key={route.path}
                path={route.path}
                render={(props) => (
                  <route.component {...route.props} routes={route.routes} />
                )}
              />
            )
          })
        }
        {
          privateRoutes.map(route => {
            return (
              <PrivateRoute exact
                key={route.path}
                path={route.path}
                {...route}
              >
              </PrivateRoute>
            )
          })
        }
        <Route
          path="*"
          render={() => {
            return (
              <div className={'no-match'}>
                <span>
                  Could not find page.
                  </span>
              </div>
            )
          }}
        />
      </Switch>
      {
        !noFooterPages.includes(location.pathname) && (
          <Footer />
        )
      }
    </>
  )
}

export default App;
