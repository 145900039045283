import '../styles/AppointmentsPage.css';
import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent,
  Typography,
  Chip,
  Tooltip,
  Button,
  Divider,
  IconButton,
  Avatar,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import Moment from 'react-moment';
import dataManager from '../utils/DataManager';
import {get, post} from '../utils/Communication';
import constants from '../constants';
import moment from 'moment'
import PaymentStatusDialog from './PaymentStatusDialog';
import GetAppIcon from '@material-ui/icons/GetApp';
import sentryManager from '../utils/SentryManager';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CardActions from '@material-ui/core/CardActions';
import PublishIcon from '@material-ui/icons/Publish';
import InfoIcon from '@material-ui/icons/Info';
import Zoom from '@material-ui/core/Zoom';
import { proceedToRazorPay } from '../utils/PaymentGateway';
import useShowPaymentDialog from '../hooks/useShowPaymentDialog';
import useAdditionalUserDetails from '../hooks/useAdditionalUserDetails';
import CallRatingDialog from './CallRatingDialog';
import useShowCallRatingDialog from '../hooks/useShowCallRatingDialog';


function AppointmentsPage(props) {

  const { 
    patientInfo
  } = props

  const paths = dataManager.getPaths();
  const consultationStatus = dataManager.getConsultationStatus();
  const cancelledBy = dataManager.getConsultationCancelledBy();
  const paymentStatus = dataManager.getPaymentStatus();
  const paymentModes = dataManager.getPaymentModes();
  const history = useHistory();

  const additionalDetails = useAdditionalUserDetails(patientInfo);
  const [showPaymentStatus, paymentParams] = useShowPaymentDialog();
  const [
    showCallRating,
    roomDurationMs,
    roomAlreadyEnded,
  ] = useShowCallRatingDialog();

  const [appointments, setAppointments] = useState([])
  const [docList, setDocList] = useState([]);
  const [fetchingAppointments, setFetchingAppointments] = useState(true);
  const [fetchingDocList, setFetchingDocList] = useState(true);
  const [noAptText, setNoAptText] = useState('No Appointments.');
  const [infoLabel, setInfoLabel] = useState(`Check-in will start the camera 
  and doctor will get notified that you are ready for consultation`)
  const [disabledFutureApts, setDisabledFutureApts] = useState([]);

  useEffect(() => {
    if (additionalDetails.isMr) {
      setInfoLabel(`Check-in will start the camera 
      and doctor will get notified that you are ready for video call`)
    }

  }, [additionalDetails])

  useEffect(() => {
    if (!patientInfo)
      return;

    if (patientInfo && patientInfo.error) {
      setFetchingAppointments(false);
      return;
    }

    fetchAppointments();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[patientInfo])

  useEffect(() => {
    if (appointments.length > 0) {
      appointments.forEach(appt => {
        appt.fetchingOrderId = false;
      })
    }
  }, [appointments])

  useEffect(() => {
    if (appointments.length > 0) {
      get(constants.endpoints.doctorsList, null, true)
        .then(res => {
          if (res.status === 200)
            return res.json()
          else {
            setFetchingDocList(false);
            sentryManager.captureMessage(
              'Failed to get Doctor List - status: ' + res.status
            )
            return [];
          }
        }, err => {
          setFetchingDocList(false);
          sentryManager.captureMessage(
            'Fetch failed for doctor list - err: ' + err
          )
        })
        .then(result => {
          if (result) {
            setDocList(result);
            setFetchingDocList(false);
          }
        })
    }
  }, [appointments])

  const fetchAppointments = () => {
    setFetchingAppointments(true);
    const appInfoQuery = {
      type: 'T',
      patient_id: patientInfo.id,
      appointment: '1'
    }
    get(constants.endpoints.getAppointmentInfo, appInfoQuery, true)
      .then(response => {
        if (response.status === 200)
          return response.json();
        else {
          setFetchingAppointments(false);
          sentryManager.captureMessage(
            'Error getting appointment - status: ' + response.status +
            ' Data: ' + JSON.stringify(appInfoQuery)
          )
        }
      })
      .then(result => {
        if (!result)
          return;
        let data = dataManager.getData();
        result.appointments.sort((apt1, apt2) => {
          let apt1Date = moment(apt1.slot.date + ' ' + apt1.slot.start_time)
          let apt2Date = moment(apt2.slot.date + ' ' + apt2.slot.start_time)
          if (apt1Date > apt2Date)
            return 1;
          if (apt1Date < apt2Date)
            return -1;
          return 0
        })
        data.appointments = result.appointments;
        if (data.appointments.length === 0)
          setNoAptText('No Appointments.')
        setAppointments(data.appointments);
        setFetchingAppointments(false);
      })
  }

  const checkInToRoom =
    (appointment, isDisabledFutureSlot, needPayment, isReconnect = false) => {
      if (isDisabledFutureSlot === null)
        isDisabledFutureSlot = disabledFutureSlot(appointment);
      if (needPayment === null)
        needPayment = checkNeedsToPay(appointment);
      if (isDisabledFutureSlot && needPayment) {
        if (!appointment.fetchingOrderId) {
          appointment.fetchingOrderId = true;
          const dataToSend = {
            doctor_id: appointment.doctor,
            appointment_id: appointment.id,
          }
          post(constants.endpoints.razorpayOrderCreate, dataToSend, true)
            .then(response => {
              if ([200, 201].includes(response.status))
                return response.json()
              else {
                appointment.fetchingOrderId = false;
                sentryManager.captureMessage(
                  'Failed to create order - status ' + response.status +
                  ' Data: ' + JSON.stringify(dataToSend)
                )
                alert('Could not proceed to payment.');
                return null
              }
            })
            .then(result => {
              if (result) {
                proceedToRazorPay(appointment.doc_name, result.order_id,
                  result.key_id, patientInfo);
              }
            })
            .catch(err => {
              appointment.fetchingOrderId = false;
              sentryManager.captureMessage(
                'Failed to create order ' + JSON.stringify(err)
              )
            })
        }
        return;
      }
      let data = dataManager.getData();
      data.selectedAppointment = appointment;
      data.selectedAppointment.isReconnect = isReconnect;
      // eslint-disable-next-line
      data.appointmentRoomDetails = null;
      history.push(paths.room, {
        from: 'afterRoomCheckinClick',
        patientInfo: patientInfo
      });
    }

  useEffect(() => {
    let timers = []
    if (disabledFutureApts.length > 0) {
      disabledFutureApts.forEach(apt => {
        const startTime = moment(apt.slot.date + ' ' + apt.slot.start_time)
        const enableCheckinInMs = startTime
          .subtract(constants.checkinBeforeMin, 'm').diff(moment())
        timers.push(setTimeout(() => {
          setDisabledFutureApts(prevVal => {
            return [...prevVal.filter(prevApt => prevApt.id !== apt.id)]
          })
        }, enableCheckinInMs))
      })
    }
    return () => {
      timers.forEach(timer => {
        clearTimeout(timer)
      })
    }
  }, [disabledFutureApts])

  const navigateToUploadReport = (event, appointment) => {
    let data = dataManager.getData();
    data.selectedAppointment = appointment;
    history.push(paths.uploadReport, {from: 'afterUploadReportSelect'});
  }

  const onPaymentModeClose = () => {
    history.replace(paths.home);
  }

  const disableSlot = (slotStart, slotEnd, appointment) => {
    const consultStatus = appointment.consultation_status;
    const paymentTimeLimitReached = checkPaymentTimeLimitExceeded(appointment);
    const isDisabledFutureSlot = disabledFutureSlot(appointment);

    const todaysDate = moment(new Date());
    if (
      todaysDate > moment(slotEnd).add(constants.slotLeewayMin, 'm') ||
      consultStatus === consultationStatus.FINISHED ||
      paymentTimeLimitReached ||
      isDisabledFutureSlot
    )
      return true

    if (
      moment(slotStart).subtract(constants.checkinBeforeMin, 'm') < todaysDate
    )
      return false

    return true;
  }

  const disabledFutureSlot = (appointment) => {
    const todaysDate = moment(new Date());
    const slotStart = moment(
      appointment.slot.date + ' ' +
      appointment.slot.start_time
    );
    const needsToPay = checkNeedsToPay(appointment);

    const isDisabledFutureSlot =  (
      needsToPay ||
      moment(slotStart).subtract(constants.checkinBeforeMin, 'm') > todaysDate
    )

    if (isDisabledFutureSlot) {
      const presentInFutureApts =
        disabledFutureApts.findIndex(apt => apt.id === appointment.id) !== -1
      //Check for preventing further updates after ui is rendered.
      if (!presentInFutureApts) {
        setDisabledFutureApts(prevVal => {
          const isPresent =
            prevVal.findIndex(apt => apt.id === appointment.id) !== -1
          if (!isPresent)
            return [...prevVal, appointment]
          else
            return prevVal
        })
      }
    }

    return isDisabledFutureSlot;
  }

  const checkPaymentDone = (appointment) => {
    const payment = appointment.payment;
    return (
      payment.mode === paymentModes.cash ||
      !payment.amount ||
      (
        payment.mode === paymentModes.razorpay &&
        payment.status === paymentStatus.success
      )
    )
  }

  const checkPaymentTimeLimitExceeded = (appointment) => {
    const paymentDone = checkPaymentDone(appointment);
    const todaysDate = moment(new Date());
    const slotEnd = moment(
      appointment.slot.date + ' ' +
      appointment.slot.end_time
    );

    return (
      !paymentDone &&
      slotEnd.add(constants.slotLeewayMin, 'm') < todaysDate
    )
  }

  const appointmentCancelStatus = (appointment) => {
    const paymentDone = checkPaymentDone(appointment);
    const paymentTimeLimitReached = checkPaymentTimeLimitExceeded(appointment);
    return {
      isCancelled: appointment.status === 'C',
      cancelledBy: appointment.cancelledBy,
      // TODO: Remove refund magic num.
      refunded: appointment.refund_status === 1,
      paymentNotMade: (!paymentDone && paymentTimeLimitReached)
    }
  }

  const getPaymentSubtext = (appointment) => {
    const paymentDone = checkPaymentDone(appointment);
    const amount = appointment.payment.amount;
    const cancelStatus = appointmentCancelStatus(appointment);

    if (paymentDone)
      return 'Fees Paid ₹' + amount;
    
    if (cancelStatus.isCancelled || cancelStatus.paymentNotMade)
      return '';

    return 'Payment Pending!';

  }

  const checkNeedsToPay = (appointment) => {
    const paymentDone = checkPaymentDone(appointment);
    const paymentTimeLimitReached = checkPaymentTimeLimitExceeded(appointment);

    return (!paymentDone && !paymentTimeLimitReached)
  }

  const getChekinBtnLabel = (slotStart, slotEnd, appointment) => {
    const consultStatus = appointment.consultation_status
    if (consultStatus === consultationStatus.FINISHED)
      return 'Done'

    const slotDisabled = disableSlot(slotStart, slotEnd, appointment);
    const isDisabledFutureSlot = disabledFutureSlot(appointment);
    
    if (
      slotDisabled && 
      !isDisabledFutureSlot &&
      consultStatus === consultationStatus.NEW
    )
      return 'Missed'

    if (checkNeedsToPay(appointment))
      return 'Pay & Confirm';

    return 'Check-In'
  
  }

  const getSlotCaptionText = (appointment) => {
    const isDisabledFutureSlot = disabledFutureSlot(appointment);
    const needsPayment = checkNeedsToPay(appointment);
    const cancelStatus = appointmentCancelStatus(appointment);
    const slotAmt = appointment.payment.amount;

    if (isDisabledFutureSlot && !needsPayment)
      return 'You can check-in 10 mins before start time.'

    if (isDisabledFutureSlot && needsPayment)
      return (<span>
        Amount to be Paid ₹{slotAmt}
      </span>)


    if (cancelStatus.cancelledBy === cancelledBy.scheduler)
      return "Auto Cancelled"


    if (cancelStatus.isCancelled && cancelStatus.refunded)
      return (<span>
        ₹{slotAmt} Refund has been initiated.
      </span>)


    if (cancelStatus.isCancelled)
      return (<span>
        Appointment has ben canceled
      </span>)

  }

  const getDocSpeciality = (appointment) => {
    const reqDoctor = docList
      .filter(doc => doc.doctor_id === appointment.doctor)
    
    if (reqDoctor.length > 1) {
      sentryManager.captureMessage(
        'Did not show speciality - Got more than one doctor with the ' +
        'same doctor id in self booking doclist api call'
      )
    }

    if (reqDoctor.length === 1) {
      return reqDoctor[0].specialities
    }

    return ''
  }

  const getDocLocation = (appointment) => {
    const reqDoctor = docList
      .filter(doc => doc.doctor_id === appointment.doctor)

    if (reqDoctor.length > 1) {
      sentryManager.captureMessage(
        'Did not show location - Got more than one doctor with the ' +
        'same doctor id in self booking doclist api call'
      )
    }

    if (reqDoctor.length === 1) {
      return reqDoctor[0].location
    }

    return ''
  }

  const handleCallRatingClosed = () => {
    fetchAppointments();
  }

  const getUploadedReportCount = (appointment) => {
    return appointment.report_count;
  }

  const renderAppointmentView = () => {
    let currSlotDate = moment(
      appointments[0].slot.date + ' '  +
      appointments[0].slot.start_time
    ).format('dddd, Do MMM YYYY')
    let addSlotDateField = true;
    const viewToRender = appointments.map((appointment) => {
      const slotStartDate = moment(
        appointment.slot.date + ' '  +
        appointment.slot.start_time
      );
      const slotEndDate = moment(
        appointment.slot.date + ' '  +
        appointment.slot.end_time
      );
      const isDisabledFutureSlot = disabledFutureSlot(appointment);
      const needsPayment = checkNeedsToPay(appointment);
      const paymentDone = checkPaymentDone(appointment);
      const paymentTimeLimitReached = 
        checkPaymentTimeLimitExceeded(appointment);
      const cancelStatus = appointmentCancelStatus(appointment);

      const slotDisabled = disableSlot(slotStartDate, slotEndDate, appointment)

      const uploadedReportCount = getUploadedReportCount(appointment);

      const docSpeciality = getDocSpeciality(appointment);
      const docLocation = getDocLocation(appointment);

      const slotDate = moment(slotStartDate);
      const slotDateFormat = slotDate.format('dddd, Do MMM YYYY')
      if (slotDateFormat !== currSlotDate) {
        currSlotDate = slotDateFormat;
        addSlotDateField = true
      }

      let currSlotView =  (
        <div key={appointment.id} >
          {
            addSlotDateField &&
            <div
              className={'seperator'}
            >
              {currSlotDate}
            </div>
          }
          <Card className="appointment-card">
            <CardContent>
              <div className="card-info">
                <div className="doc-name">
                  <Tooltip enterTouchDelay={40} 
                    title={'Dr. ' + appointment.doc_name}
                  >
                    <Avatar className={'user icon'}>
                      <AccountCircleIcon />
                    </Avatar>
                  </Tooltip>
                  <div className="user info">
                    <Tooltip enterTouchDelay={40} 
                      title={'Dr. ' + appointment.doc_name}
                    >
                      <Typography variant="subtitle1" noWrap={true} 
                        className={'user name'}
                      >
                        Dr.&nbsp;{appointment.doc_name}
                      </Typography>
                    </Tooltip>
                    {
                      fetchingDocList &&
                      <>
                        <Skeleton height={16} width={60} animation="wave" />
                        <Skeleton height={16} width={60} animation="wave" />
                      </>
                    }
                    {
                      !fetchingDocList &&
                      <>
                        <Tooltip enterTouchDelay={40}
                          title={docSpeciality}
                          hidden={docSpeciality === ''}
                        >
                          <Typography variant="body2" className={'speciality'}
                            display="block"
                            noWrap={true}
                          >
                            {docSpeciality}
                          </Typography>
                        </Tooltip>
                        <Typography variant="body2" className={'location'}
                          display="block"
                          noWrap={true}
                          hidden={docLocation === ''}
                        >
                          {docLocation}
                        </Typography>
                      </>
                    }
                    {
                      appointment.payment && 
                      appointment.payment.mode === paymentModes.razorpay &&
                      !!appointment.payment.amount &&
                      <Typography className={'user payment'}
                        variant="subtitle2" display="block">
                          {getPaymentSubtext(appointment)}
                      </Typography>
                    }
                  </div>
                </div>
                <div
                  className={
                    'checkin-chip-container ' +
                    (cancelStatus.isCancelled ? 'cancelled ' : ' ')
                  }
                >
                  {
                    !cancelStatus.isCancelled &&
                    <>
                      <Typography
                        className={
                          'checkin time  ' +
                          ((isDisabledFutureSlot && needsPayment) ?
                            'pending-pay ' : ' ')
                        }
                        color="textSecondary"
                      >
                        <ScheduleIcon />
                        <Moment
                          format="hh:mm a"
                          date={slotStartDate}
                        />
                      </Typography>
                      <Chip
                        className={
                          "doxper-btn checkin-chip " +
                          ((slotDisabled && !needsPayment) ?
                            'disabled ' : ' ') +
                          ((isDisabledFutureSlot && needsPayment) ?
                            'dox btn light ' : ' ')
                        }
                        label={getChekinBtnLabel(
                          slotStartDate, slotEndDate, appointment
                        )}
                        onClick={(event) => {
                          checkInToRoom(appointment, isDisabledFutureSlot,
                            needsPayment
                          )
                        }}
                      />
                      <Tooltip title={infoLabel}
                        className={
                          ((isDisabledFutureSlot && needsPayment) ?
                            'display none ' : ' ')
                        }
                        enterTouchDelay={40}
                        TransitionComponent={Zoom}
                        leaveTouchDelay={10000}
                        arrow
                      >
                        <IconButton className="info chip">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  {
                    (cancelStatus.isCancelled) &&
                    <span className="slot-cancelled">
                      Cancelled
                    </span>
                  }
                </div>
              </div>
              <Typography
                className={
                  'slot-time-caption caption ' +
                  ((isDisabledFutureSlot || cancelStatus.isCancelled) ?
                    'show ' : ' ')
                }
                variant="body2" 
                align="center"
                display="block"
                gutterBottom
              >
                {getSlotCaptionText(appointment)}
              </Typography>
            </CardContent>
            <>
              {(paymentDone || !paymentTimeLimitReached) &&
                !additionalDetails.isMr &&
                <CardActions className='card-action-cnt'>
                  <Button color="primary"
                    className='card-action btn reports'
                    onClick={(event) => {
                      navigateToUploadReport(event, appointment)
                    }}
                    endIcon={<PublishIcon />}
                  >
                    {
                      uploadedReportCount > 0 && 
                      <>
                        ({uploadedReportCount})&nbsp;Upload&nbsp;Reports
                      </>
                    }
                    {
                      uploadedReportCount <= 0 &&
                      <>
                        Upload&nbsp;Reports
                      </>
                    }
                  </Button>
                  {
                    appointment.pres_link &&
                    <>
                      <Divider
                        className={'action divider'} orientation="vertical"
                        light={true}
                        flexItem />
                      <Button
                        href={appointment.pres_link}
                        target={'_blank'}
                        className={"card-action btn pres"}
                        endIcon={<GetAppIcon />}
                      >
                        Prescription
                      </Button>
                    </>
                  }

                </CardActions>
              }
            </>
          </Card>
        </div>
      )
      if (addSlotDateField)
        addSlotDateField = false
      return currSlotView;
    })
    return viewToRender;
  }

  let returnObj = null;
  if (!fetchingAppointments) {
    if (appointments.length === 0)
      returnObj = (
        <Typography
          variant="h4"
          component="h4"
        >
          {noAptText}
        </Typography>
      )
    else
      returnObj = (
        <>
          <Typography className={"list header"} variant="body2" align="center"
            display="block" gutterBottom>
            My Appointments
        </Typography>
          {renderAppointmentView()}
        </>
      )
  } else {
    returnObj =  (
      <>
        <Skeleton height={200} animation="wave" />
        <Skeleton height={200} animation="wave" />
        <Skeleton height={200} animation="wave" />
      </>
    )
  }

  return (
    <>
      {returnObj}
      <PaymentStatusDialog
          shouldOpen={showPaymentStatus}
          paymentParams={paymentParams}
          onPaymentModeClose={onPaymentModeClose}
      />
      <CallRatingDialog 
        shouldOpen={showCallRating}
        onReconnectToRoom = {checkInToRoom}
        patientInfo = {patientInfo}
        roomDurationMs = {roomDurationMs}
        roomAlreadyEnded = {roomAlreadyEnded}
        onCallRatingClose = {handleCallRatingClosed}
      />
    </>
  )
}

export default AppointmentsPage;
