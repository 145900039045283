import VideoFrameChecker from './VideoFrameChecker';
import constants from '../constants';

class VideoTrackChecker {
    constructor() {
        if (!VideoTrackChecker.videoTrackChecker)
            VideoTrackChecker.videoTrackChecker = this;
        this.frameChecker = null;
        this.frameStats = {};
        return VideoTrackChecker.videoTrackChecker;
    }

    startTest(stream, isStandAlone, isMounted) {
        const videoStream = stream.clone()
        videoStream.getAudioTracks().forEach(track => track.stop())
        let endTimeout = 2000;
        if (isStandAlone)
            endTimeout = 5000;
        
        let rejectTimeout = null;
        return new Promise((resolve, reject) => {
            const videoElt = document.createElement('video')
            videoElt.setAttribute('autoplay', '');
            videoElt.setAttribute('muted', '');
            videoElt.setAttribute('playsinline', '')
            videoElt.width = '320';
            videoElt.height = '160';
            videoElt.srcObject = videoStream
            videoElt.play();
            this.frameChecker = new VideoFrameChecker(videoElt)
            setTimeout(()=> {
                clearInterval(rejectTimeout);
                this.stopTest();
                videoStream.getTracks().forEach(track => track.stop())
                videoElt.pause();
                videoElt.remove();
                if (isMounted.state)
                    resolve();
            }, endTimeout);
            rejectTimeout = setTimeout(() => {
                if (isMounted.state)
                    reject({name: 'timeout', message: 'Video Check timeout'})
            }, 1000 * constants.deviceTestTimeoutSec)
        })
    }

    stopTest() {
        this.frameChecker.stop()
        this.frameStats = this.frameChecker.frameStats;
    }

}

const videoTrackChecker = new VideoTrackChecker();

export default videoTrackChecker;
