import constants from '../constants';

export const proceedToRazorPay = (docName, orderId, keyId, patientInfo) => {
    const razrPayForm = document.createElement('form')
    const mobile = patientInfo.mobile;
    razrPayForm.setAttribute('method', 'POST')
    razrPayForm.setAttribute('action', constants.RAZOR_PAY_CHECKOUT);
    razrPayForm.style.display = 'none';
    razrPayForm.innerHTML = `
        <input type="hidden" name="key_id" value="${keyId}"/>
        <input type="hidden" name="order_id" value="${orderId}"/>
        <input type="hidden" name="name" value="Dr. ${docName}"/>
        <input type="hidden" name="description" value="Doxper Video Consultation Appointment"/>
        <input type="hidden" name="prefill[contact]" value="${mobile}">
        <input type="hidden" name="prefill[email]" value="payment${mobile}@informds.com">
        <input type="hidden" name="callback_url" value="${
            constants.apiUrl + constants.endpoints.razorpayCallback
        }"/>
        <input type="hidden" name="cancel_url" value="${
            constants.apiUrl + constants.endpoints.razorpayCancel
        }?order_id=${orderId}"/>
    `
    document.body.append(razrPayForm);
    razrPayForm.submit();
}
