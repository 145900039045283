import '../styles/SlotTabPanel.css';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  ButtonBase,
  Zoom,
} from '@material-ui/core';
import moment from 'moment'
import Brightness3OutlinedIcon from '@material-ui/icons/Brightness3Outlined';
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import Brightness5OutlinedIcon from '@material-ui/icons/Brightness5Outlined';
import { get } from '../utils/Communication';
import constants from '../constants';
import { Skeleton } from '@material-ui/lab';
import sentryManager from '../utils/SentryManager';

function SlotTabPanel(props) {

  const {
    selectedDate,
    panelFor,
    docDetails,
    dateFor,
    onSlotSelected,
    updateAvailableText,
    isCollapseOpen,
  } = props


  const slotBoundaries = {
    afternoon: moment(dateFor + ' 12:00:00'),
    evening: moment(dateFor + ' 15:00:00'),
    night: moment(dateFor + ' 20:00:00')
  }

  const [fetchingSlots, setFetchingSlots] = useState(true);
  const [groupingSlots, setGroupingSlots] = useState(true)
  const [slots, setSlots] = useState([])
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)

  const [groupedSlots, setGroupedSlots] = useState({
    morning: [],
    afternoon: [],
    evening: [],
    night: [],
  });

  useEffect(() => {
    if (selectedDate.momentDate === panelFor.momentDate &&
      slots.length === 0
    ) {
      const dataToSend = {
        date: selectedDate.momentDate.format('YYYY-MM-DD'),
        doctor_id: docDetails.doctor_id
      }
      get(constants.endpoints.getSlotsForTheDay, dataToSend, true)
        .then(res => {
          if (res.status === 200)
            return res.json();
          else {
            setFetchingSlots(false)
            sentryManager.captureMessage(
              'Error fetching slots for a date - status: ' + res.status + 
              ' Data: ' + JSON.stringify(dataToSend)
            )
          }
        })
        .then(unfilteredSlots => {
          if (unfilteredSlots) {
            const formatedSlots = filterAndAddDisplayTime(unfilteredSlots)
            setSlots(formatedSlots)
            setFetchingSlots(false)
          }
        })
    }
  // eslint-disable-next-line
  }, [selectedDate, panelFor])

  useEffect(() => {
    if (slots.length > 0) {
      setGroupedSlots(prevGroupedSlots => {
        let newGroupedSlots = {}
        for (const key of Object.keys(prevGroupedSlots)) {
          newGroupedSlots[key] = [];
        }
        slots.forEach(slot => {
          newGroupedSlots[getSlotGroup(slot)].push(slot)
        })
        return newGroupedSlots;
      })
      setGroupingSlots(false)
    } else if (slots.length === 0 && !fetchingSlots) {
      setGroupingSlots(false)
    }
    // eslint-disable-next-line
  }, [slots])

  useEffect(() => {
    if (selectedDate.momentDate === panelFor.momentDate && 
      !fetchingSlots
    ){
      updateAvailableText(
        panelFor, 
        slots.some(slot => slot.appointments > 0)
      )
    }
    // eslint-disable-next-line
  }, [slots, fetchingSlots])

  const filterAndAddDisplayTime = (unfilteredSlots) => {
    const today = moment();
    return unfilteredSlots.filter(slot => {
      if (slot.availability === 'DE' || slot.mr_only)
        return false;
      const slotStart = moment(slot.date + ' ' + slot.start_time)
      
      slot.momentStart = slotStart;
      slot.displayTime = slotStart.format('HH:mm A')
      return slotStart >= today
    })
  }

  const getSlotGroup = (slot) => {
    const slotStart = slot.momentStart;

    if (slotStart < slotBoundaries.afternoon)
      return 'morning';

    if (slotStart >= slotBoundaries.afternoon &&
      slotStart < slotBoundaries.evening
    )
      return 'afternoon';

    if (slotStart >= slotBoundaries.evening &&
      slotStart < slotBoundaries.night
    )
      return 'evening';

    return 'night'
  }

  const handleSlotSelected = (selectedSlot) => {
    if (selectedSlot.appointments !== 0) {
      setSelectedTimeSlot(prevSelected => {
        if (prevSelected && prevSelected.id === selectedSlot.id)
          return null;
        return selectedSlot;
      })
    }
  }
  
  const proceedToBooking = () => {
    onSlotSelected(docDetails, selectedTimeSlot)
  }

  const showLoadingSkeleton = () => {
    return (
      <>
        <Skeleton height={40} width={70} animation="wave" />
        <Skeleton height={40} width={70} animation="wave" />
        <Skeleton height={40} width={70} animation="wave" />
      </>
    )
  }

  const getGroupedSlotsView = (groupedSlots) => {
    return (
      <div
        className={"slot-timings mobile" + (
          fetchingSlots && groupingSlots ? 'loading ': ' '
        )}
      >
        {
          fetchingSlots && groupingSlots &&
          showLoadingSkeleton()
        }
        {
          !fetchingSlots && slots.length > 0 && !groupingSlots &&
          groupedSlots.map(slot => {
            return (
              <Button
                className={'slot-timing ' + 
                  ((selectedTimeSlot &&
                    selectedTimeSlot.id === slot.id)? 'selected ': ' ')
                }
                key={slot.id}
                size="small"
                variant={slot.appointments === 0 ? 'outlined' : 'contained'}
                onClick={() => handleSlotSelected(slot)}
                disabled={slot.appointments === 0}
              >
                {slot.displayTime}
              </Button>
            )
          })
        }
      </div>
    )
  }

  return (
    <div
      className="slot-tab-panel"
      hidden={selectedDate.momentDate !== panelFor.momentDate}
    >
      {
        slots.length === 0 && !fetchingSlots &&
        <Typography variant="body2"
          className="slot-tab no-slots"
          align="center"
        >
          No slots available.
        </Typography>
      }
      {
        !(slots.length === 0 && !fetchingSlots) &&
        <>
          <div className="morning-slots"
            hidden={!groupingSlots && groupedSlots.morning.length === 0}
          >
            <div className="slot-title">
              <WbSunnyOutlinedIcon />
                Morning
            </div>
            {getGroupedSlotsView(groupedSlots.morning)}
          </div>
          <div className="afternoon-slots"
            hidden={!groupingSlots && groupedSlots.afternoon.length === 0}
          >
            <div className="slot-title">
              <Brightness5OutlinedIcon />
                Afternoon
            </div>
            {getGroupedSlotsView(groupedSlots.afternoon)}
          </div>

          <div className="evening-slots"
            hidden={!groupingSlots && groupedSlots.evening.length === 0}
          >
            <div className="slot-title">
              <Brightness3OutlinedIcon className="evening-icon" />
                Evening
            </div>
            {getGroupedSlotsView(groupedSlots.evening)}
          </div>

          <div className="night-slots"
            hidden={!groupingSlots && groupedSlots.night.length === 0}
          >
            <div className="slot-title">
              <NightsStayOutlinedIcon />
                Night
            </div>
            {getGroupedSlotsView(groupedSlots.night)}
          </div>
        </>
      }
      {
        <Zoom in={(selectedTimeSlot && isCollapseOpen) ? true : false}>
          <ButtonBase
            className="book slot tab"
            onClick={proceedToBooking}
          >
            Proceed to Book
          </ButtonBase>
        </Zoom>
      }
    </div>
  )
}

export default SlotTabPanel;
