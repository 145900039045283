import '../styles/TncPage.css';
import React from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../utils/CommonMakeStyles'

function TncPage(props) {

    const commonStyle = commonStyles();

    const tncList = [
        `To store and process my health records on Doxper (www.doxper.com), 
        a cloud based digitisation service used by the clinic and/or by the 
        consulting doctor. This includes information already shared by me with 
        the clinic and/or consulting doctor till date.`,
        `To contact me via email/SMS etc. with respect to transactional and/or 
        medical promotional messages relevant to me that are sent by the 
        clinic and/or consulting doctor.`,
        `Further, I understand that the laws that protect privacy and the 
        confidentiality of medical information also apply to telemedicine, 
        and that no information obtained in the use of telemedicine which 
        identifies me will be disclosed to researchers or other entities 
        without my consent.`
    ]

    return (
        <div className='tnc_content land-padding'>
            <Typography variant="h5" component="h5"
                className={commonStyle.doxperVideoTitle}
            >
                BY ENTERING THE OTP, AND FURTHER PROCEEDING,
            </Typography>
            <Typography 
                className="tnc head" 
                variant="body1"
            >
                As per applicable legal requirements I, as a patient or 
                guardian/caregiver of the patient, hereby authorise and give 
                my consent to the clinic, the consulting doctor and its 
                authorised vendor(s)
            </Typography>
            <List
                component="li"
            >
                {tncList.map((item, index) => (
                    <Typography 
                        key={index} 
                        variant="body2" 
                        className={'tnc info'} 
                        gutterBottom
                    >
                        {index + 1}. {item}
                    </Typography>
                ))}

            </List>
        </div>
    );
}

export default TncPage;
