import * as Sentry from '@sentry/browser';
import constants from '../constants';

class SentryManager {
    constructor() {
        this.userId = '';
        this.transactionId = Date.now() + '-' + Math.random().toFixed(2);
        if (!SentryManager.sentryManager) {
            SentryManager.sentryManager = this
        }
        return SentryManager.sentryManager;
    }

    init() {
        Sentry.init({
            dsn: constants.SENTRY_DSN,
            release: process.env.REACT_APP_SENTRY_RELEASE,
            attachStacktrace: true,
            maxValueLength: 500,
        });
    }

    configureScope() {
        Sentry.configureScope(scope => {
            scope.setTag('user_mode', 'Patient Doxper Telemedicine');
            scope.setTag('transaction_id', this.transactionId);
        });
    }

    setTag(name, value) {
        Sentry.setTag(name, value);
    }

    setUser(id) {
        this.userId = id;
        Sentry.setUser({id: id})
    }

    updateUserId(id) {
        this.userId = id;
    }

    captureMessage(message) {
        Sentry.captureMessage(message);
    }

    captureException(message) {
        Sentry.captureException(new Error(message));
    }

    setBreadcrumb({msg, category=null, data=null}) {
        Sentry.addBreadcrumb({
            message: msg,
            category: category || 'telemed-general',
            data: data || null
        })
    }

    msgWithScope(message, level, tagName, tagValue) {
        Sentry.withScope((scope) => {
            scope.setLevel(level)
            scope.setTag(tagName, tagValue)
            Sentry.captureMessage(message);
        })
    }

}

const sentryManager = new SentryManager();

export default sentryManager;
