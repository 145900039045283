import '../styles/SideMenu.css';
import React, {
  useState,
} from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Divider,
  Typography,
} from '@material-ui/core'
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import dataManager from '../utils/DataManager';
import usePatientName from '../hooks/usePatientName';
import useLogout from '../hooks/useLogout';


function SideMenu(props) {

  const {
    openDrawer,
    onSideMenuClose,
    patientInfo,
  } = props;

  const paths = dataManager.getPaths();
  const history = useHistory();
  const { pathname } = useLocation();
  const patientName = usePatientName(patientInfo);

  const [logoutState, setLogoutState] = useState({
    logout: false,
  })
  useLogout(logoutState);

  const navigate = (path) => {
    onSideMenuClose();
    history.push(path);
  }

  const logout = () => {
    setLogoutState({
      logout: true,
    })
  }

  return (
    <Drawer

      anchor={'left'}
      open={openDrawer}
      onClose={onSideMenuClose}
    >
      <div className={'drawer-container'}>
        <div className={'side-menu user-info justify center'}>
          <Avatar className={'patient avatar'}>
            <span>{patientName.substring(0, 1)}</span>
          </Avatar>
          <Typography variant='body1' gutterBottom>
            {patientName}
          </Typography>
          <Typography variant='body2' gutterBottom>
            {(patientInfo && patientInfo.mobile) ? patientInfo.mobile : ' '}
          </Typography>
        </div>
        <Divider variant="middle" />
        <List component='nav' className="side-menu list">
          <ListItem
            button
            className={'side-menu list-item ' + (
              pathname === paths.home ? 'active ' : ' '
            )}
            onClick={() => navigate(paths.home)}
          >
            <ListItemText primary={'My Appointments'} />
          </ListItem>
          <ListItem
            button
            className={'side-menu list-item ' + (
              pathname === paths.doctorListing ? 'active ' : ' '
            )}
            onClick={() => navigate(paths.doctorListing)}
          >
            <ListItemText primary={'Book Appointment'} />
          </ListItem>
          <ListItem
            button
            className={'side-menu list-item '}
            onClick={logout}
          >
            <ListItemText primary={'Logout'} />
          </ListItem>
        </List>
      </div>

    </Drawer>
  )
}

export default SideMenu;
