import '../styles/SlotSelection.css';
import React, { useState, useRef } from 'react';
import {
  Tab,
  Tabs,
} from '@material-ui/core';
import moment from 'moment';
import constants from '../constants';
import SlotTabPanel from './SlotTabPanel';

moment.updateLocale('en', {
  calendar: {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'DD MMM',
    lastDay: 'DD MMM',
    lastWeek: 'DD MMM',
    sameElse: 'DD MMM'
  }
});

function getInitialDatesList() {
  const today = moment()
  const dateList = [{
    momentDate: today,
    displayDate: today.calendar(),
  }]
  for (let i = 1 ; i <= constants.maxSlotDaysToShow ; i++) {
    const date = moment().add(i, 'day');
    dateList.push({
      momentDate: date,
      displayDate: date.calendar(),
    })
  }
  return dateList
}

function SlotSelection(props) {

  const {
    docDetails,
    onSlotSelected,
    onSetAvailabilityText,
    isCollapseOpen,
  } = props

  const dateList = useRef(getInitialDatesList())
  const [selectedDate, setSelectedDate] = useState({
    date: dateList.current[0],
    idx: 0
  });

  const handleTabChange = (newIdx) => {
    setSelectedDate({
      date: dateList.current[newIdx],
      idx: newIdx
    });

  }

  const changeTab = () => {
    const newIdx = selectedDate.idx + 1
    if (dateList.current[newIdx]) {
      handleTabChange(newIdx);
    } else {
      updateAvailableText(null, true);
    }
  }

  const updateAvailableText = (date, isAvailable) => {
    if (isAvailable) {
      onSetAvailabilityText((date && date.momentDate) ? 
        date.momentDate.calendar() : 'Unvailable')
    } else {
      changeTab();
    }
  }

  return (
    <>
      <div className={'slot-selection-container'}>
        <Tabs
          className={'slot-selection-tabs'}
          value={selectedDate.idx}
          onChange={(event, newIdx) => {handleTabChange(newIdx)}}
          variant="scrollable"
          scrollButtons="auto"
        >
          {
            dateList.current.map((date, idx) => {
              return (
                <Tab
                  key={docDetails.id + ' ' + idx}
                  label={date.displayDate}
                  id={docDetails.id + ' ' + idx}
                  value={idx}
                />
              )
            })
          }
        </Tabs>
        {
          dateList.current.map((date, idx) => {
            return (
              <SlotTabPanel
                key={date.displayDate}
                docDetails={docDetails}
                selectedDate={selectedDate.date}
                onSlotSelected={onSlotSelected}
                panelFor={date}
                dateFor={date.momentDate.format('YYYY-MM-DD')}
                updateAvailableText={updateAvailableText}
                isCollapseOpen={isCollapseOpen}
              />
            )
          })
        }
      </div>
    </>
  )
}

export default SlotSelection;
