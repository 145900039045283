import React from 'react';

export const faqList = [
    {
      primaryLabel: 'APPOINTMENTS & PAYMENTS',
      content: [
        {
          secondaryLabel: 'Can I choose which doctor to consult with?',
          content: `Doxper is not a doctor discovery platform. However, if 
          you are aware that the doctor of your choice is using Doxper for 
          video consultation, you can request the booking link from your 
          doctor and self book the appointment.`,
        },
        {
          secondaryLabel: 'What is the self-booking process?',
          content: `Click on the link provided by your doctor. Log in with your
           OTP after accepting T&Cs. Under ‘Book Appointment’ you will be able 
           to see your doctor details and can proceed to select the available 
           slot.`,
        },
        {
          secondaryLabel: 'Can I pay my doctor through this platform?',
          content: `Yes, if you are self-booking the appointment, you will have
           to pay the doctor’s fees before the appointment can be confirmed. If
           your doctor is booking on your behalf, please make the payment as 
           soon as the appointment is confirmed to ensure you are able to start
           the consultation on time.`,
        },
        {
          secondaryLabel: 'How do I cancel/reschedule an appointment?',
          content: (
            <>
              <span>
                Please <b>contact your doctor</b> who can cancel or 
                reschedule the appointment on your behalf. 
              </span>
            </>
          ),
        },
        {
          secondaryLabel: 'What happens if I am late for the appointment?',
          content: `The appointment will be valid for up to 15 minutes after 
          the scheduled start time. Ideally, you should check in 10 minutes 
          before the start time. As a courtesy, please call your doctor to let 
          them know or to request a rescheduled appointment as they may no 
          longer be available.`,
        },
        {
          secondaryLabel: `Will I get a refund if either I or my doctor does 
          not turn up for the appointment (No Show)?`,
          content: (
            <>
              <span>
                If you or your doctor are late for your appointment 
                by 15 minutes or more, you can call your doctor to 
                reschedule. Your doctor will be able to book a 
                new appointment on your behalf with zero fees.
              </span><br /><br />
              <span>
                In the case of ‘Patient No Show’: If a new appointment 
                is not booked by the doctor, the current appointment 
                will automatically be cancelled by midnight of the same 
                day. In this case, there will be no refunds issued 
                and no option to book a free appointment.
              </span><br /><br />
              <span>
                In the case of ‘Doctor No Show’: If you are not able to reach 
                your doctor, or if your doctor takes no action to book a new 
                appointment, your refund will be processed within 15 working 
                days.<b>In case this happens, you will be notified of 
                the same.</b>
              </span>
            </>
          ),
        },
        {
          secondaryLabel: 'What happens if my doctor cancels my appointment?',
          content: (
            <span>
              Your refund will be processed within 15 working days and <b>you 
              will be notified of the same.</b>
            </span>
          ),
        },
        {
          secondaryLabel: `I paid for the appointment but it is not showing 
          anywhere after I log in`,
          content: (
            <span>
              Please contact us at&nbsp;
              <a href="tel:+918882599599">8882 599 599</a>&nbsp;
              and we will attempt to fix the problem on the spot or issue
              you a full refund.
            </span>
          ),
        }

      ]
    },
    {
      primaryLabel: 'PREPARING FOR THE CONSULTATION',
      content: [
        {
          secondaryLabel: `I am using video consultation for the first time. 
          How do I prepare?`,
          content: `Please ensure your Internet connection is good and the 
          room is well lit and noise-free. Use earphones to avoid echo 
          issues. If you have any relevant reports, you can upload these 
          before checking in. You should ‘Check In’ 10 minutes prior to 
          your scheduled appointment.`,
        },
        {
          secondaryLabel: `I have not received the OTP while trying to login`,
          content: (
            <span>
              You will receive an OTP within 30 seconds. 
              If not, you can tap ‘Resend’ to try again. 
              If you are still facing issues, please contact us at&nbsp;
              <a href="tel:+918882599599">8882 599 599</a>.
            </span>
          ),
        },
        {
          secondaryLabel: `Can I use a different mobile number for video 
          consultation?`,
          content: `If your doctor has booked your appointment, you can only 
          use the same mobile number to login on which they have booked you. 
          This is the same number on which you received the confirmation SMS.`
        },
        {
          secondaryLabel: `After login, I am not seeing any video consultation 
          appointment`,
          content: (
            <span>
              Please check if you have logged in using the right 
              mobile number and try refreshing the page. 
              If you are still facing issues, please contact us at&nbsp;
              <a href="tel:+918882599599">8882 599 599</a>.
            </span>
          ),
        },
        {
          secondaryLabel: `How do I notify my doctor when I am ready for the 
          video consultation?`,
          content: (
            <span>
              When you are ready, tap on <i>‘Check In’</i> to enter the 
              waiting room. <b>Your doctor will be notified. </b> You 
              should check in 10 minutes prior to your scheduled appointment.
            </span>
          ),
        },
        {
          secondaryLabel: `Why is the ‘Check In’ button not clickable?`,
          content: `You can check-in 10 minutes before your scheduled 
          appointment. Before this, the option to check-in will be disabled. 
          Previous appointments or those that have lapsed for more than 15 
          minutes from the start time will also be disabled.`
        },
      ],
    },
    {
      primaryLabel: 'SECURITY, PRIVACY & TECHNICAL',
      content: [
        {
          secondaryLabel: `Is Doxper safe and secure for Video Consultation?`,
          content: `Yes, we have leading security protocols in place, 
          including ‘https’ browser encryption and HIPAA compliant web 
          servers located in India.`,
        },
        {
          secondaryLabel: `How is my privacy maintained?`,
          content: `Your personal information and prescriptions are encrypted 
          and only available to you and your doctor. We do not share your 
          personal information with any third party.`,
        },
        {
          secondaryLabel: `Is it safe to make payments through Doxper?`,
          content: `Doxper uses Razorpay, India’s leading provider of payment 
          gateway services to process your payment. The platform is PCI 
          compliant and ISO 27001:2013 certified.`,
        },
        {
          secondaryLabel: `Can I use my Desktop/Laptop for video 
          consultation?`,
          content: `Yes, supported browsers include 
          Chrome/Firefox/Safari/Samsung. Your device must support a 
          camera and microphone.`,
        },
        {
          secondaryLabel: `The service does not seem to be working my 
          Phone/Browser`,
          content: (
            <span>
              Please check if your supported browser is from the 
              following list: Chrome/Firefox/Safari/Samsung. 
              If you are still facing issues, please contact us at&nbsp;
              <a href="tel:+918882599599">8882 599 599</a>.
            </span>
          ),
        },
        {
          secondaryLabel: `How do I test my browser’s Video/Audio settings?`,
          content: `After you ‘Check-In’, your browser will automatically 
          test your video/audio settings.`,
        },
        {
          secondaryLabel: `My video does not start after checking in`,
          content: (
            <span>
              Please check if you have given permission to Doxper for 
              video/audio access? If not, please close the browser and 
              ‘Check In’ again. If you are still facing issues, 
              please contact us at&nbsp;
              <a href="tel:+918882599599">8882 599 599</a>.
            </span>
          ),
        },
        {
          secondaryLabel: `What if the video call gets disconnected during 
          the consultation?`,
          content: `You can open your web browser and check-in again. 
          Please do inform your doctor.`,
        },
      ],
    },
    {
      primaryLabel: 'AFTER THE CONSULTATION',
      content: [
        {
          secondaryLabel: `How will I receive my prescription?`,
          content: `After the consultation is over, you will receive an SMS 
          with the link to your prescription. You can also download your 
          prescription from the same page you used to check-in.`,
        },
        {
          secondaryLabel:`Can I access my Video recording?`,
          content: `No, your videos are not recorded. As per compliance, we 
          only keep the logs of your consultation, such as the start and end 
          time.`,
        },
        {
          secondaryLabel:`How can I view my past appointments and 
          prescriptions? `,
          content: `After you have logged in with your mobile number and OTP, 
          tap on Menu and on ‘My Appointments’.`,
        },
        {
          secondaryLabel:`If I have consulted more than one doctor on Doxper, 
          how do I access my prescriptions?`,
          content: `Once you log in through any doctor’s link, you can access 
          all of your past appointments with any doctor under 
          ‘My Appointments’.`,
        }
      ],
    },
  ]