import '../styles/MenuBar.css';
import React, { 
  useState,
  useEffect,
} from 'react';
import dataManager from '../utils/DataManager';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Avatar,
  IconButton,
} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
import { 
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import SideMenu from './SideMenu';
import usePatientName from '../hooks/usePatientName';
import useAdditionalUserDetails from '../hooks/useAdditionalUserDetails';
import useLogout from '../hooks/useLogout';


function MenuBar(props) {
  
  const {
    patientInfo
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const paths = dataManager.getPaths();

  const history = useHistory();
  const match = useRouteMatch();
  const patientName = usePatientName(patientInfo);
  const additionalDetails = useAdditionalUserDetails(patientInfo);

  const [logoutState, setLogoutState] = useState({
    logout: false,
  })
  useLogout(logoutState);

  const [showBookAptLink, setShowBookAptLink] = useState(false);

  useEffect(() => {
    if ([paths.home, paths.paymentStatus].includes(match.path)) {
      setShowBookAptLink(true)
    } else {
      setShowBookAptLink(false)
    }
  // eslint-disable-next-line
  }, [match.path])

  const openBookApt = () => {
    history.push(paths.doctorListing);
  }

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  }

  const onSideMenuClose = () => {
    setMenuOpen(false);
  }

  const logout = () => {
    setLogoutState({
      logout: true
    })
  }

  return (
    <>
      <AppBar
        position="static"
        className={"app-bar menu-bar"}
      >
        <Toolbar>
          {
            !additionalDetails.isMr && 
            <IconButton 
              edge="start"
              aria-label="menu"
              onClick={toggleMenu}
              className={'menu btn'}
            >
              {!menuOpen && <MenuIcon />}
              {menuOpen && <MenuOpenIcon />}
            </IconButton>
          }
          <Skeleton 
            className={patientInfo && !patientInfo.error ? 'hidden': ''} 
            variant="circle" 
            width={40} height={40} 
          />
          <Skeleton
            className={'title ' +
              (patientInfo && !patientInfo.error ? 'hidden' : '')}
            width={200}
          ></Skeleton>
          {
            patientName.substring(0, 1).length > 0 && 
            <Avatar 
              className={'avatar ' +
                (!patientInfo || patientInfo.error ? 'hidden' : '')}
              color="inherit"
            >
              {patientName.substring(0, 1)}
            </Avatar>
          }
          <Typography 
            className={"title " +
              (!patientInfo || patientInfo.error ? 'hidden' : '')} 
            variant="h6"
            color="inherit"
          >
            {patientName}
          </Typography>
          {
            showBookAptLink &&
            <Button color="inherit" onClick={
              !additionalDetails.isMr ? openBookApt : logout
            }
              className={'book-new-btn'}
            >
              {
                !additionalDetails.isMr &&
                <span>
                  Book&nbsp;New
                </span>
              }
              {
                additionalDetails.isMr &&
                'Logout'
              }
            </Button>
          }
        </Toolbar>
      </AppBar>
      <SideMenu 
        openDrawer={menuOpen}
        onSideMenuClose={onSideMenuClose}
        patientInfo={patientInfo}
      />
    </>
  )
}

export default MenuBar;
