import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import sentryManager from '../utils/SentryManager';
import dataManager from '../utils/DataManager';

function PrivateRoute(route) {

  const paths = dataManager.getPaths();
  const preventReloadFrom = dataManager.getPreventReloadPages();
  const inAppNavigationPages = dataManager.getInAppNavigationPages();
  const inAppNavigationStates = dataManager.getInAppNavigationStates();

  const checkSessionValid = () => {
    let validSession = false;
    let authToken = dataManager.getFromLocalStorage('authToken')
    if (authToken) {
      authToken = JSON.parse(authToken)
      if (new Date(authToken.validity) > new Date()) {
        sentryManager.setTag('token', authToken.token);
        validSession = true;
      }
    }
    return validSession;
  }

  const componentToRender = ({location, match}) => {
    const state = location.state || null;
    const data = dataManager.getData();
    let redirectTo = {
      pathname: paths.login,
      state: { from: location, matchPath: match.path }
    }
    const validSession = checkSessionValid();
    const redirect = (
      <Redirect
        to={redirectTo}
      />
    );

    if (validSession) {
      if (inAppNavigationPages.includes(location.pathname) &&
        (state && inAppNavigationStates.includes(state.from))
      )
        return <route.component {...route.props} routes={route.routes}/>;

      if (
        [paths.room, paths.uploadReport].includes(location.pathname) &&
        (!(state && state.from === 'afterUploadReportSelect') ||
        !(state && state.from === 'afterRoomCheckinClick'))
      ) {
        redirectTo.pathname = paths.home;
        return redirect;
      }

      if ([paths.confirmSlot]
          .includes(location.pathname) &&
        (!(state && state.from === 'afterListingPage') ||
          !(state && state.from === 'afterConfirmSlot'))
      ) {
        redirectTo.pathname = paths.doctorListing;
        return redirect;
      }

      if (!preventReloadFrom.includes(location.pathname))
        return <route.component {...route.props} routes={route.routes}/>;
    }

    if (paths.deviceTest === location.pathname && 
      !(state && state.from === 'afterClick')
    ) {
      redirectTo.pathname = paths.home;
      return redirect;
    }
    
    if (paths.deviceTest === location.pathname && 
      (state && state.from === 'afterClick')
    )
      return <route.component {...route.props} routes={route.routes}/>;

    // for login -> otp
    if (paths.otp === location.pathname &&
      (state && state.from === paths.login) &&
      data.fromLandingPage
    )
      return <route.component {...route.props} routes={route.routes}/>;

    return redirect;
  }

  return (
    <Route
      key={route.key}
      path={route.path}
      exact
      render={componentToRender}
    />
  );
}

export default PrivateRoute;
