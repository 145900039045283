import '../styles/UploadReport.css';
import React, {useState, useEffect, useRef} from 'react';

import dataManager from '../utils/DataManager';
import constants from '../constants';

import { makeStyles } from '@material-ui/core/styles';
import { 
    Button,
    ButtonBase,
    Dialog,
    Snackbar,
  } from '@material-ui/core';
import MuiAlertWrapper from './MuiAlertWrapper';

import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PublishIcon from '@material-ui/icons/Publish';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { post, get } from '../utils/Communication';
import { Skeleton } from '@material-ui/lab';
import sentryManager from '../utils/SentryManager';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
}));

function UploadReport(props) {

    const history = useHistory();
    const paths = dataManager.getPaths();

    const [uploadOptionVisible, setUploadOptionVisiblity] = useState(false);   
    const [photos, setPhotos] = useState([]);
    const [uploadedPhotos, setUploadedPhotos] = useState([]);
    const [existingFiles, setExistingFiles] = useState([])
    const [isLoding, setLoding] = useState(true)
    const [openImageViewer, setOpenImageViewer] = useState(false)
    const [selectedImgUrl, setSelectedImgUrl] = useState(null)
    const [snackBarState, setSnackBarState] = useState({
        open: false,
        severity: 'success',
        msg: ''
    });

    const uploadInputRef = useRef(null);
    const cameraInputRef = useRef(null);

    let data = dataManager.getData();
    const classes = useStyles();

    useEffect(() => {
        const dataToSend = {
            doc_patient: data.selectedAppointment.doc_patient,
        }

        get(constants.endpoints.uploadLabReport, dataToSend, true)
            .then(res => {
                if (res.status === 200)
                    return res.json();
                else {
                    setLoding(false)
                    sentryManager.captureMessage(
                        'Could not load existing reports - status: ' +
                        res.status + ' Data: ' + JSON.stringify(dataToSend)
                    )
                    return []
                }
            })
            .then(reports => {
                const tempExistingFiles = []
                reports.forEach((report, repIdx) => {
                    const created = report.created_at ?
                        moment(report.created_at).format('Do MMM YYYY') : '–';
                    report.pages.forEach((page, pgIdx) => {
                        tempExistingFiles.push({
                            existingUrl: page.url,
                            name: `Report - ${repIdx}.${pgIdx}`,
                            created: created,
                        })
                    })
                })
                setExistingFiles(tempExistingFiles);
                setLoding(false)
            })
    // eslint-disable-next-line 
    }, [])

    const uploadReportClick = () => {
        setUploadOptionVisiblity(true)
    }
    const handleUploadOptionClose = ()=>{
        setUploadOptionVisiblity(false)
    }
    const getExistingReports = () => {
        if (photos.length > 0){
            setExistingFiles([...photos, ...existingFiles])
            setPhotos([])
            setLoding(false)
            return ''
        }
    }
    const onDrop = (inputRef) => {
        let files = inputRef.current.files
        let updatedList = []
        let filelength = files.length
        let counter = 0
        for (const fl of files){
            const width = 600;
            const fileName = fl.name;
            const reader = new FileReader();
            reader.readAsDataURL(fl);
            // TODO: remove the disable linter and fix warnings.
            // eslint-disable-next-line
            reader.onload = ev => {
                const img = new Image();
                img.src = ev.target.result;
                img.onload = et => {
                    const scaleFactor = width / img.width;
                    const height = img.height * scaleFactor;
                    const elem = document.createElement('canvas');
                    elem.width = width;
                    elem.height = height;
                    const ctx = elem.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    ctx.canvas.toBlob((blob) => {
                        const newfile = new File([blob], fileName, {
                            type: 'image/jpeg',
                            lastModified: Date.now()
                        });
                        let dataFL = {
                            'name' : fileName,
                            'content' : newfile,
                            'uploded_url' : 'xpz',
                            'compressed' : 'N'
                        }
                        let isFileExist = photos.filter((fl)=>{
                            return fl.name === fileName
                        }).length > 0
                        if (!isFileExist){
                            updatedList = [dataFL, ...updatedList]
                        }
                        counter++;
                        if (filelength === counter){
                            inputRef.current.value = '';
                            updatedList = [...updatedList, ...photos]
                            setPhotos(updatedList)
                        }
                    }, 'image/jpeg', 1);
                }
                reader.onerror = error => console.log(error);
            };
        }
        
    }
    const updateUploadedPhotos = (newPhotos) => {
        let newList = [...newPhotos, ...uploadedPhotos]
        setUploadedPhotos(newList)
    }
    const uploadImages = () => {
        let newList = []
        let files = photos.map(newls =>{
            let isExist = uploadedPhotos.filter(oldls => oldls.name === newls.name).length > 0
            if (!isExist){
                newList.push(newls)
                return newls
            }
            return ''
        })
        setLoding(true)
        setUploadOptionVisiblity(false)
        if (newList.length !== 0){
            updateUploadedPhotos(newList)
            uploadFile(constants.endpoints.uploadContent, files)
        }
        else
            setLoding(false);
    }
    const uploadFile = (endpoint, fileArr, useAuth=true) => {
        const url = constants.apiUrl + endpoint; 
        let promisesArr = []
        fileArr.forEach(file => {
            if (!file)
                return;
            let formdata = new FormData();
            formdata.append("file", file.content, file.name);
            let fetchOptions = {
                method: 'POST',
                headers: {
                },
                body: formdata,
                redirect: 'follow'
            }
            if (useAuth) {
                let authToken = JSON.parse(dataManager.getFromSessionStorage('authToken'))
                if (!authToken)
                    authToken = JSON.parse(dataManager.getFromLocalStorage('authToken'))
                fetchOptions.headers['Authorization'] = 'Token ' + authToken.token
            }
            promisesArr.push(
                fetch(url, fetchOptions)
                    .then(res => {
                        return {
                            serverResponse: res,
                            filename: file.name,
                        }
                    }, 
                        err => {
                            sentryManager.captureMessage('Error uploading a file ' + JSON.stringify(err))
                            return {
                                error: err,
                                filename: file.name,
                            }
                        }
                    )
            )
        })


        Promise.all(promisesArr).then(function(resolvedVals) {
            let promArr = []
            let unUploadedFilenames = []
            resolvedVals.forEach(resolvedVal=> {
                if (resolvedVal.serverResponse.status &&
                    resolvedVal.serverResponse.status === 200
                )
                    promArr.push(resolvedVal.serverResponse.json())
                else if (resolvedVal.error) {
                    unUploadedFilenames.push(resolvedVal.filename)
                } else {
                    unUploadedFilenames.push(resolvedVal.filename)
                    sentryManager.captureMessage(
                        'File upload error - status: ' +
                        resolvedVal.serverResponse.status
                    )
                }
            })
            if (unUploadedFilenames.length > 0) {
                alert('Following files were not uploaded: ' +
                    unUploadedFilenames.join(', '))
            }

            const totalUploadedFiles = resolvedVals.length - unUploadedFilenames.length;

            setSnackBarState(oldState => {
                let newState = { ...oldState }
                newState.msg = `${totalUploadedFiles} File(s) Uploaded`;
                return newState;
            })

            Promise.all(promArr).then(function(resData) {
                uploadLabReport(resData)
            })
        })
    }
    const [labPages, setLabPages] = useState([]);
    const uploadLabReport = (res) => {
        let counter = 1
        let newList = [...labPages]
        let newPhotoList = []
        res.map(resObj=>{
            let resData = resObj[0]
            let labPage = {
                'page_num' : counter++,
                'url' : resData.url
            }
            newList = [...newList, labPage]

            let photo = photos.filter(a=>a.name === resData.name)
            photo[0].existingUrl = resData.url
            photo[0].created = moment().format('Do MMM YYYY');
            newPhotoList.push(photo[0])
            return ''
        })
        setLabPages(newList)
        setPhotos(newPhotoList)
        let docId = data.selectedAppointment.doctor
        let patientId = data.selectedAppointment.doc_patient
        let labData = {
            'doctor_id': docId,
            'doc_patient': patientId,
            'pages' : newList
        }
        
        post(constants.endpoints.uploadLabReport, labData, true).then( res =>{
            if (res.status === 200){
                setSnackBarState(oldState => {
                    let newState = { ...oldState }
                    newState.open = true;
                    return newState;
                });
                getExistingReports()
                setLabPages([])
            } else {
                setLabPages([]);
                setLoding(false);
                alert('Could not update lab reports.');
                sentryManager.captureMessage(
                    'Could not update lab reports with lab data after uploading file(s) successfully - status: ' +
                    res.status + ' Data: ' + JSON.stringify(labData)
                )
            }
        })
    }
   
    const removeImage = (event) =>{
        let fileName = ''
        if(event.target.nodeName === 'svg')
            fileName = event.target.parentElement.getAttribute('filename')
        else
            fileName = event.target.getAttribute('filename')
        let updatedList = []
        photos.map(fl=>{
            if (fl.name !== fileName)
                updatedList.push(fl)
            return ''
        })
        setPhotos(updatedList)
    }
    
    const backBtnClick =()=>{
        history.replace(paths.home);
    }
    const imageOnLoad = (e) =>{
        
    }

    const showImageDialog = (imageUrl) => {
        setSelectedImgUrl(imageUrl)
        setOpenImageViewer(true)
    }

    const handleImageViewerClose = () => {
        setOpenImageViewer(false)
        setSelectedImgUrl(null)
    }

    const handleSnackbarClose = () => {
        setSnackBarState(oldState => {
            const newState = { ...oldState }
            newState.open = false;
            return newState;
        });
    }

    return (
        <div className='upload-report-page'>
            <div className='upload-report-header'>
                <div className='back-navigation-arrow'>
                    <Button onClick={backBtnClick}><ArrowBackIcon/></Button>
                </div>
                <div className='header-doc-name'>Dr {data.selectedAppointment.doc_name}</div>
            </div>
            <div className='upload-report-cnt'>
            <div className='uploaded-file-cnt'>
                <div className='image-preview-box'>
                    {isLoding && (
                            <div className="reports-loading-container">
                                <Skeleton animation="wave" variant="rect" />
                                <Skeleton animation="wave" variant="rect" />
                                <Skeleton animation="wave" variant="rect" />
                                <Skeleton animation="wave" variant="rect" />
                            </div>
                    )}
                    {existingFiles.length === 0 && !isLoding && (
                        <div className='blank-impage-preview-box'>No Reports Found!</div>
                    )}
                    {existingFiles.length > 0 && !isLoding && (
                        <div className='impage-preview-box-count'>{existingFiles.length} Uploads</div>
                    )}
                    {!isLoding && existingFiles.map((fl, i)=> (
                        <div className='preview-img-cnt pointer' key={i}
                            onClick={() => showImageDialog(fl.existingUrl)}
                        >
                            <img src={fl.existingUrl} alt={fl.name}/>
                            <div className={'preview-img-date text center'}>
                                {fl.created}
                            </div>
                        </div>)
                    )}
                    
                    </div>
                </div>
            <Modal
                className={classes.modal}
                open={uploadOptionVisible}
                onClose={handleUploadOptionClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={uploadOptionVisible}>
                <div className='upload-option-cnt'>
                    
                    <div className={photos.length > 0 ? 'upload-file-option' : 'upload-file-option photo-selected'}>
                        <div className='upload-file-action-btn'>
                            <div className='imageUploader'>
                                <IconButton aria-label="capture photo" className='upload-btn icon-image'
                                    onClick={() => cameraInputRef.current.click()}
                                >
                                    <AddAPhotoIcon />
                                    <div className='action-icon-label'>Camera</div>
                                </IconButton>
                                <input type="file" id="imageFile"  className='upload-input-camera' capture="user" accept="image/*" 
                                    onChange={() => onDrop(cameraInputRef)}
                                    ref={cameraInputRef}
                                    hidden
                                />
                            </div>
                            <div className='imageUploader icon-gallery'>
                                <IconButton  aria-label="add file from system" className='upload-btn'
                                    onClick={() => uploadInputRef.current.click()}
                                >
                                    <AddPhotoAlternateIcon />
                                    <div className='action-icon-label'>Gallery</div>
                                </IconButton>
                                <input className='upload-input' type="file" id="file" name="file" multiple accept="image/*" 
                                    onChange={() => onDrop(uploadInputRef)} 
                                    ref={uploadInputRef} 
                                    hidden
                                />
                            </div>
                        </div>
                           
                        
                        <div className='image-preview-box'>
                            {photos.map((fl, i)=> (
                                    (<div className='preview-img-cnt' key={i}>
                                        <div className='preview-img-btn'><button onClick={removeImage} filename={fl.content.name}><CloseIcon /></button></div>
                                        <img src={URL.createObjectURL(fl.content)} key={i} alt='#' iscompressed={fl.compressed} onLoad={imageOnLoad}/>
                                    </div>)
                            ))}
                        </div>
                    </div>
                    {photos.length > 0 && (
                    <div className='upload-option-close-modal'>
                        <ButtonBase size="small" color="primary" onClick={uploadImages}>
                            <PublishIcon />
                        </ButtonBase>   
                    </div>
                    )}
                    {photos.length === 0 && (
                    <div className='upload-option-close-modal'>
                        <ButtonBase size="small" color="primary" onClick={(event) => {setUploadOptionVisiblity(false)}}>
                            <CloseIcon />
                        </ButtonBase>   
                    </div>
                    )}
                </div>
                </Fade>
            </Modal>

                <div className='upload-report-add-cnt'>
                    <div className='upload-report-addmore-btn-cnt'>
                        <ButtonBase size="small" color="primary" onClick={(event) => {uploadReportClick(event) }}>
                            <AddIcon />
                        </ButtonBase>
                    </div>
                    <div className='upload-report-addmore-label'>Upload More</div>
                </div>
            </div>
            <div className='upload-report-done' onClick={backBtnClick}><Button>DONE</Button></div>
            <Dialog
                open={openImageViewer && setSelectedImgUrl}
                onClose={handleImageViewerClose}
            >
                <img
                    className={'upload-report image-viewer'}
                    src={selectedImgUrl}
                    alt="selected report"
                >
                </img>
            </Dialog>
            <Snackbar open={snackBarState.open} autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlertWrapper
                    onClose={handleSnackbarClose}
                    severity={snackBarState.severity}
                >
                    {snackBarState.msg}
                </MuiAlertWrapper>
            </Snackbar>
        </div>
    );
}

export default UploadReport;
