import '../styles/FaqPage.css';
import React, {
  useState,
} from 'react';
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import { faqList } from '../utils/FaqDetails';

const SubFaqExpansionPanel = withStyles({
  root: {
    '&$expanded': {
      margin: 'auto',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
  },
  expanded: {},
})(ExpansionPanel)

const SubExpantionSummary = withStyles({
  root: {
    '&$expanded': {
      color: '#00aeff',
    }
  },
  expanded: {},
})(ExpansionPanelSummary)

function FaqPage(props) {

  const [expandedIdx, setExpandedIdx] = useState(0);

  const handleExpandedChange = (newExpanded, index) => {
    setExpandedIdx(newExpanded ? index : -1)
  };

  return (
    <>
      <div className='faq-nav'>
        <div className='faq-label text center'>FAQs</div>
        <div className='faq-care'>
          <div className='care-no'>
            <HeadsetMicIcon />&nbsp;
            <a href="tel:+918882599599">8882599599</a>
          </div>
          <div className='care-days'>(Mon - Sat: 10AM - 8PM)</div>
        </div>
      </div>
      <div className="faq-container">
        {
          faqList.map((faq, idx) => (
            <ExpansionPanel
              expanded={expandedIdx === idx}
              onChange={
                (event, newExpanded) => handleExpandedChange(newExpanded, idx)
              }
              TransitionProps={{ unmountOnExit: true }}
            >
              <ExpansionPanelSummary
                className="faq-primary-summary"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>{faq.primaryLabel}</Typography>
              </ExpansionPanelSummary>
              {
                faq.content.map((subFaq, subIdx) => (
                  <SubFaqExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    square
                  >
                    <SubExpantionSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography variant="subtitle2">
                        {subFaq.secondaryLabel}
                      </Typography>
                    </SubExpantionSummary>
                    <ExpansionPanelDetails>
                      <Typography variant="body2">
                        {subFaq.content}
                      </Typography>
                    </ExpansionPanelDetails>
                  </SubFaqExpansionPanel>
                ))
              }
            </ExpansionPanel>
          ))
        }
      </div>
    </>
  );
}

export default FaqPage;
