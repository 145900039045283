import React from 'react';
import {
  Typography,
} from '@material-ui/core'
import commonStyles from '../utils/CommonMakeStyles'

function WelcomeMessage() {

  const commonStyle = commonStyles();

  return (
    <div>
      <Typography
        className={commonStyle.colorBlue + ' ' + commonStyle.welcomeStyle}
        variant="h6"
        component="h6"
      >
        Welcome to
        </Typography>
        <Typography
        className={commonStyle.doxperLanding}
        variant="h5"
        component="h5"
      >
        Doxper video consultation
        </Typography>
    </div>
  )
}

export default WelcomeMessage;
