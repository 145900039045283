import '../styles/ConfirmUserDetailsPage.css';
import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  makeStyles,
  ThemeProvider,
  createMuiTheme
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab'
import { 
  useLocation,
  useHistory,
} from 'react-router-dom';
import { 
  patch,
  post,
} from '../utils/Communication';
import constants from '../constants';
import usePatientName from '../hooks/usePatientName';
import sentryManager from '../utils/SentryManager';
import dataManager from '../utils/DataManager';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: '2em',
    width: '50%',
    maxWidth: '240px',
    display: 'flex',
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00aeff',
    },
    secondary: {
      main: '#00aeff',
    },
    error: {
      main: '#f93422',
    }
  },
});

function ConfirmUserDetailsPage(props) {


  const {
    docDetails,
    patientInfo,
    selectedSlot,
    onSlotSelected,
    onPatientInfoUpdated,
    onNeedSnackBar
  } = props;

  const patientName = usePatientName(patientInfo);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const paths = dataManager.getPaths();

  const ageInputRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const [gender, setGender] = useState('');
  const [fullName, setFullName] = useState('');
  const [fname, setFname] = useState('')
  const [lname, setLname] = useState('')
  const [formDirty, setFormDirty] = useState(false);
  const [fnameInvalid, setFnameInvalid] = useState(false);
  const [lnameInvalid, setLnameInvalid] = useState(false);
  const [ageInvalid, setAgeInvalid] = useState(false);
  const [age, setAge] = useState(0);
  const [mobile, setMobile] = useState('');
  const [isCreationPage, setIsCreationPage] = useState(false);

  useEffect(() => {
    const state = location.state || null;
    if (!state || state.from !== 'afterConfirmSlot')
      setIsCreationPage(true);
  }, [location])

  useEffect(() => {
    const state = location.state || null;
    if (state && state.mobile) {
      setMobile(state.mobile);
    } else if (patientInfo && patientInfo.mobile) {
      setMobile(patientInfo.mobile);
    }
  },[patientInfo, location])

  useEffect(() => {
    setFullName(patientName)
  }, [patientName])

  useEffect(() => {
    if (patientInfo && patientInfo.sex) {
      if (patientInfo.sex.toLowerCase() === 'm')
        setGender('m');
      else if (patientInfo.sex.toLowerCase() === 'f')
        setGender('f');
      else
        setGender('');
    }

    if (patientInfo && patientInfo.age) {
      setAge(patientInfo.age)
    }
    
  }, [patientInfo])

  useEffect(() => {
    if (firstNameRef.current) {
      setFnameInvalid(
        firstNameRef.current.value.trim() === ''
      )
    }
    if (lastNameRef.current) {
      setLnameInvalid(
        lastNameRef.current.value.trim() === ''
      )
    }
  }, [formDirty])

  useEffect(() => {
    if (ageInputRef.current) {
      setAgeInvalid(
        isNaN(ageInputRef.current.value) ||
        ageInputRef.current.value === ''
        || ageInputRef.current.value.toString().indexOf('.') !== -1
      )
    }
  },[age, formDirty])

  useEffect(() => {
    if (firstNameRef.current) {
      setFnameInvalid(
        firstNameRef.current.value.trim() === ''
      )
    }
  }, [fname])

  useEffect(() => {
    if (lastNameRef.current) {
      setFnameInvalid(
        lastNameRef.current.value.trim() === ''
      )
    }
  }, [lname])

  const handleGenderChanged = (event) => {
    setGender(event.target.value);
  }

  const handleAgeChange = (event) => {
    setAge(ageInputRef.current.value)
  }

  const handleConfirmDetails = (event) => {
    event.preventDefault();
    setFormDirty(true);
    if (ageInvalid || gender === '' ||
      fnameInvalid || lnameInvalid
    ) {
      setAgeInvalid(ageInvalid)
      return;
    }
    let dataToSend = {
      sex: gender,
      age: ageInputRef.current.value,
    }
    let sendDataPromise = null
    if (!(!isCreationPage || fullName !== '')) {
      dataToSend = {
        ...dataToSend,
        first_name: firstNameRef.current.value,
        last_name: lastNameRef.current.value,
        mobile: dataManager.data.mobileNumber,
        additional_info: {
          fresh_user: true
        }
      }
    }
    if (!(!isCreationPage || fullName !== '')) {
      sendDataPromise = post(constants.endpoints.getPatientInfo, dataToSend, true)
    } else {
      sendDataPromise = patch(constants.endpoints.getPatientInfo, dataToSend, true)
    }
    sendDataPromise.then(res => {
        if ([200, 201].includes(res.status))
          return res.json()
        else {
          alert('Could not update details. Try again')
          sentryManager.captureMessage(
            'Error updating patient details - status: ' + res.status + 
            ' Data: ' + JSON.stringify(dataToSend)
          )
        }
      })
      .then(result => {
        if (result) {
          onPatientInfoUpdated(result);
          let replaceUrl = null;
          const state = location.state || null;
          if (state && state.replaceWith)
            replaceUrl = state.replaceWith;
          if (!isCreationPage)
            onSlotSelected(docDetails, selectedSlot, true, replaceUrl);
          else {
            if (replaceUrl)
              history.replace(replaceUrl);
            else
              history.replace(paths.home);
          }
          onNeedSnackBar('Details Updated', 'success');
        }
      })
  }

  if (!patientInfo) {
    return (
      <Skeleton
        height={480}
        variant="rect"
        animation="pulse"
      >
      </Skeleton>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Card className="confirm-user-details-card">
        <form
          className={'user-details-form'}
          onSubmit={handleConfirmDetails}
        >
          <CardContent>
            <Typography variant="caption" className={'confirm-user head'}
              display="block"
            >
              {
                (!isCreationPage || fullName !== '') &&
                'Confirm Details'
              }
              {
                !(!isCreationPage || fullName !== '') &&
                'Please Provide Patient Details'
              }
            </Typography>

            {
              (!isCreationPage || fullName !== '') &&
              <FormControl
                className={classes.formControl}
              >
                <TextField
                  label="Full Name"
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                    value: fullName,
                  }}
                />
              </FormControl>
            }
            {
              !(!isCreationPage || fullName !== '') &&
              <>
                <FormControl
                  className={classes.formControl}
                >
                  <TextField
                    label="First Name"
                    inputRef={firstNameRef}
                    InputProps={{
                      readOnly: false,
                      disabled: false,
                      required: true,
                    }}
                    onChange={() => setFname(firstNameRef.current.value)}
                    error={fnameInvalid}
                    helperText={fnameInvalid ? 'This cannot be empty ' : ''}
                  />
                </FormControl>
                <FormControl
                  className={classes.formControl}
                >
                  <TextField
                    label="Last Name"
                    inputRef={lastNameRef}
                    InputProps={{
                      readOnly: false,
                      disabled: false,
                      required: true,
                    }}
                    onChange={() => setLname(lastNameRef.current.value)}
                    error={lnameInvalid}
                    helperText={lnameInvalid ? 'This cannot be empty ' : ''}
                  />
                </FormControl>
              </>
            }

            <FormControl 
              className={classes.formControl}
            >
              <TextField
                label="Age"
                inputProps={{
                  inputMode: 'numeric',
                  maxLength: 3,
                  value: age,
                }}
                inputRef={ageInputRef}
                onChange={handleAgeChange}
                error={ageInvalid}
                helperText={ageInvalid ? 'Age should be a whole number ' : ''}
              />
            </FormControl>

            <FormControl 
              className={classes.formControl}
              error={gender === ''}
            >
              <InputLabel id="confirmGenderLabel">Gender</InputLabel>
              <Select
                labelId="confirmGenderLabel"
                value={gender}
                onChange={handleGenderChanged}
                autoWidth
              > 
                <MenuItem value={'m'}>Male</MenuItem>
                <MenuItem value={'f'}>Female</MenuItem>
              </Select>
            </FormControl>

            <FormControl 
              className={classes.formControl}
            >
              <TextField
                label="Phone Number"
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  value: mobile,
                }}
              />
            </FormControl>

          </CardContent>
          <CardActions
            className={'card-action-cnt'}
          >
            <Button
              variant="contained"
              className="confirm-btn"
              type="submit"
            >
              {
                (!isCreationPage || fullName !== '') &&
                'Confirm'
              }
              {
                !(!isCreationPage || fullName !== '') &&
                'Submit'
              }
            </Button>
          </CardActions>
        </form>
      </Card>
    </ThemeProvider>
  )
}

export default ConfirmUserDetailsPage;
