import { useEffect, useState } from 'react';

function usePatientName(patientInfo) {
    const [patientName, setPatientName] = useState('');

    useEffect(() => {
        if (patientInfo &&
            (patientInfo.first_name || patientInfo.first_name)
        ) {
            const name = patientInfo.first_name + ' ' + (patientInfo.last_name || '');
            setPatientName(name.trim())
        }
    }, [patientInfo])

    return patientName;
}

export default usePatientName;
