// import style module so as to not affect css on any other page.
import styles from '../styles/FeeReceipt.module.css';
import React, {
  useState,
  useEffect,
} from 'react';
import {
  useRouteMatch,
} from 'react-router-dom';
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';
import { get } from '../utils/Communication';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import dataManager from '../utils/DataManager';
import doxperLogo from '../images/doxper-svg-logo.svg';
import moment from 'moment'
import constants from '../constants';
import sentryManager from '../utils/SentryManager';

const Skeleton = withStyles({
  root: {
    margin: '0 auto',
  }
})(MuiSkeleton)

function FeeReceipt() {

  const match = useRouteMatch();
  const paths = dataManager.getPaths();

  const [receiptId, setReceiptId] = useState(null);
  const [receiptDetails, setReceiptDetails] = useState({
    amountPaid: '',
    paymentId: '',
    paidOn: '',
    method: '',
    mobile: '',
    name: ''
  })
  const [fetchingReceiptDetails, setFetchingReceiptDetails] = useState(true)
  const [failedGettingDetails, setFailedGettingDetails] = useState(false)

  useEffect(() => {
    if (match.path === paths.receipt &&
      match.params && match.params.receipt
    ) {
      setReceiptId(match.params.receipt)
    }
  // eslint-disable-next-line
  }, [match])

  useEffect(() => {
    if (receiptId !== null) {
      const dataToSend = {
        receipt_id: receiptId
      }
      get(constants.endpoints.feeReceipt, dataToSend, false)
        .then(res => {
          if (res.status === 200)
            return res.json()
          else {
            setFailedGettingDetails(true);
            setFetchingReceiptDetails(false);
            alert('Could not get fee receipt')
            sentryManager.captureMessage(
              'Could not get receipt details - status: ' + res.status + 
              ' Data: ' + JSON.stringify(dataToSend)
            )
          }
        })
        .then(result => {
          if (result) {
            setReceiptDetails({
              doctorName: result.doctor_name,
              amountPaid: result.amount_paid,
              paymentId: result.payment_id,
              paidOn: result.paid_on ?
                moment(result.paid_on, 'YYYY-MM-DD').format('Do MMM, YYYY') :
                'NA',
              method: getMethodText(result.method, result.method_details),
              mobile: result.mobile,
              patientName: result.patient_name,
            })
            setFetchingReceiptDetails(false)
          }
        })
    }
  }, [receiptId])

  const getMethodText = (method, details) => {
    if (method === 'card')
      return `${method} XXXX-XXXX-XXXX-${details}`;
    return method ? `${method} ${details}` : 'NA';
  }

  return (
    <div
      className={styles.receiptPadding +' main-page-wrapper '}
    >
      <div className={styles.receiptContainer}>
        <div className={styles.logoContainer}>
          <span className={styles.logoText}> Powered By </span>
          <img src={doxperLogo} alt="Doxper Logo" 
            className={styles.doxperLogo}
          />
        </div>
        <div className={styles.docNameContainer + ' text center '}>
          {
            fetchingReceiptDetails &&
            <Skeleton height={40} width={300}></Skeleton>
          }
          {
            !fetchingReceiptDetails &&
            <span>
              Dr. {receiptDetails.doctorName}
            </span>
          }
        </div>
        <Paper elevation={2} 
          className={styles.infoPaper}
        >
          <div className={styles.amountContainer}>
            <div className={styles.amount + ' text center '}>
            {
              fetchingReceiptDetails &&
              <Skeleton height={45} width={100}></Skeleton>
            }
            {
              !fetchingReceiptDetails &&
              `₹ ${receiptDetails.amountPaid}`
            }
            </div>
            {
              fetchingReceiptDetails &&
              <Skeleton height={24} width={200}></Skeleton>
            }
            {
              !fetchingReceiptDetails && !failedGettingDetails &&
              <div className={styles.amountStatus}>
                <CheckCircleIcon /> Paid Successfully
              </div>
            }
          </div>
          {
            fetchingReceiptDetails &&
            <>
              <Skeleton height={64} width={'92%'}></Skeleton>
              <Skeleton height={64} width={'92%'}></Skeleton>
              <Skeleton height={64} width={'92%'}></Skeleton>
              <Skeleton height={64} width={'92%'}></Skeleton>
              <Skeleton height={64} width={'92%'}></Skeleton>
            </>
          }
          {
            !fetchingReceiptDetails &&
            <List>
              <ListItem
                className={styles.listItem}
              >
                <ListItemText
                  primary={'Payment Id'}
                  secondary={receiptDetails.paymentId}
                />
              </ListItem>
              <ListItem
                className={styles.listItem}
              >
                <ListItemText
                  primary={'Paid On'}
                  secondary={receiptDetails.paidOn}
                />
              </ListItem>
              <ListItem
                className={styles.listItem}
              >
                <ListItemText
                  primary={'Method'}
                  secondary={receiptDetails.method}
                />
              </ListItem>
              <ListItem
                className={styles.listItem}
              >
                <ListItemText
                  primary={'Mobile Number'}
                  secondary={receiptDetails.mobile}
                />
              </ListItem>
              <ListItem
                className={styles.listItem}
              >
                <ListItemText
                  primary={'Patient Name'}
                  secondary={receiptDetails.patientName}
                />
              </ListItem>
            </List>
          }
        </Paper>
      </div>
    </div>
  )
}

export default FeeReceipt;
