import '../styles/DocListingCard.css';
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  Tooltip,
  CardActions,
  Button,
  Collapse,
  CircularProgress,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Moment from 'react-moment';
import SlotSelection from './SlotSelection';


function DocListingCard(props) {

  const {
    docDetails,
    onSlotSelected,
    showBookApt,
    onResetWasSelectedState,
  } = props;

  const [showSlotSelection, setShowSlotSelection] = useState(
    docDetails.wasSelected || false
  )
  const [availableOn, setAvailableOn] = useState(
    docDetails.availabilityText || ''
  )

  useEffect(() => {
    setShowSlotSelection(docDetails.wasSelected);
  }, [docDetails.wasSelected])

  const setAvailabilityText = (text) => {
    if (!docDetails.availabilityText) {
      let availabilityText = ''
      if (text !== 'Unvailable' && availableOn === '')
        availabilityText = 'Available ' + text
      else if (text === 'Unvailable')
        availabilityText = text
  
      docDetails.availabilityText = availabilityText;
      setAvailableOn(docDetails.availabilityText)
    }
  }

  const getAvailabilityText = () => {
    if (availableOn === '')
      return (
        <span>
          Availability
          <CircularProgress className={'circle-loader'} size={10}/>
        </span>
      )
    return availableOn;
  }

  const toggleSlotSelection = () => {
    onResetWasSelectedState()
    setShowSlotSelection(prevState => !prevState)
  }

  return (
    <Card
      className={'listing-card'}
    >
      <CardContent>
        <div className="info-container">
          <div className="doc-info-container">
            <Tooltip enterTouchDelay={40}
              title={'Dr. ' + docDetails.full_name}
            >
              <Avatar className="doc avatar">
                <AccountCircleIcon className={'doc icon'} />
              </Avatar>

            </Tooltip>
            <div className="doc-info">
              <Tooltip enterTouchDelay={40}
                title={'Dr. ' + docDetails.full_name}
              >
                <Typography display="block" variant="subtitle1" noWrap={true}
                  className="doc name"
                >
                  Dr.&nbsp;{docDetails.full_name}
                </Typography>
              </Tooltip>
              <Tooltip enterTouchDelay={40}
                title={docDetails.specialities}
              >
                <Typography display="block" variant="body2" noWrap={true}
                  className="speciality"
                >
                  {docDetails.specialities}
                </Typography>
              </Tooltip>
              <Tooltip enterTouchDelay={40}
                title={docDetails.location}
              >
                <Typography display="block" variant="body2" noWrap={true}
                  className="location"
                >
                  {docDetails.location}
                </Typography>
              </Tooltip>

            </div>
          </div>
          <div className="slot-info">
            <div className="availability available text center">
              {getAvailabilityText()}
            </div>
            {
              docDetails.last_visited &&
              <div className="last-visit">
                Last Visit <Moment format={'Do MMM'}>
                  {docDetails.last_visited}
                </Moment>
              </div>
            }
            <div className="amount">
              {
                !!docDetails.fees &&
                <span>
                  <span className="fee">₹{docDetails.fees}</span>&nbsp;fee
                </span>
              }
            </div>
          </div>
        </div>
      </CardContent>
      {
        showBookApt &&
        <>
          <CardActions
            className={'card-action-cnt'}
          >
            <Button
              className={'book-apt dox btn light'}
              onClick={toggleSlotSelection}
            >
              Book Appointment
            </Button>
          </CardActions>

          <Collapse in={showSlotSelection || docDetails.wasSelected}>
            <CardContent className={"card slot-selection-content"}>
              <SlotSelection 
                onSlotSelected={onSlotSelected}
                docDetails={docDetails}
                onSetAvailabilityText={setAvailabilityText}
                isCollapseOpen={showSlotSelection}
              />
            </CardContent>
          </Collapse>
        </>
      }
    </Card>
  )
}

export default DocListingCard;
