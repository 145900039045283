import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import sentryManager from './utils/SentryManager';
import { BrowserRouter } from "react-router-dom";

if (
  process.env.NODE_ENV === 'production' && 
  process.env.REACT_APP_SENTRY_RELEASE
) {
  sentryManager.init();
  sentryManager.configureScope();
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
