import sentryManager from "./SentryManager";
import constants from '../constants';

class MediaStreamManager {

    constructor() {
        if (!MediaStreamManager.streamManager) {

            this.defaultConstraints = {
                audio: true,
                video: {
                  facingMode: 'user'
                }
            }

            this.errors = {
                'AbortError': `We cannot access the camera/mic right now.
                    If some other application or tab is using camera/mic close them and
                    refresh the page to try again.`,
                'NotReadableError': `We cannot access the camera/mic right now.
                    If some other application or tab is using camera/mic close them and
                    refresh the page to try again.
                `,
                'NotAllowedError':`You need to grant camera and mic permissions
                    to check into consultation. Please enable the permissions
                    and refresh the page to try again`,
                'NotFoundError': `Could not get appropriate camera/mic tracks.`,
                'OverconstrainedError': `Could not get appropriate camera/mic tracks.`,
                'TypeError': `Could not access camera/mic.`,
                'NoOutputFromMic': 'Microphone is not able to provide audio.',
                'NoVideoFrames': 'Camera could not provide video frames.',
                'timeout': 'This check timed out, please refresh the page and try again',
            }

            this.stream = null;
            MediaStreamManager.streamManager = this;
        }
    }

    getStream(mediaStreamConstraints = null) {
        if (!mediaStreamConstraints)
            mediaStreamConstraints = this.defaultConstraints;
        let timeout = null;
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
                .then(stream => { 
                    clearInterval(timeout)
                    resolve(stream)
                })
                .catch(err => {
                    clearInterval(timeout)
                    reject(err)
                });
            timeout = setTimeout(() => {
                reject({name: 'timeout', message: 'Get media stream timeout'})
            }, 1000 * constants.deviceTestTimeoutSec)
        })
    }

    getErrorMessageToDisplay(err) {
        if (this.errors[err.name])
            return this.errors[err.name]
        else
            return err.message
    }

    handleError(err, showAlert = false) {
        if (showAlert)
            alert('Error getting media stream - error: ' + err.message);
        sentryManager.captureMessage(
            'Error getting media streams - error: ' + err.name +
            ': ' + err.message
        );
    }

}

const streamManager = new MediaStreamManager();

export default streamManager
