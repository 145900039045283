import '../styles/PaymentStatusDialog.css';
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Slide,
  Button,
} from '@material-ui/core'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

function PaymentStatusDialog(props) {

  const {
    shouldOpen,
    paymentParams,
    onPaymentModeClose
  } = props

  const isPositiveOutcome = ['booking-complete', 'success']
    .includes(paymentParams.status)

  const getStatusHeading = () => {
    switch(paymentParams.status) {
      case 'success':
        return 'Payment Successful'
      case 'failed':
        return 'Payment Failed'
      case 'cancelled':
        return 'Payment Cancelled'
      case 'booking-complete':
        return 'Appointment Booked'
      default: return ''
    }
  }

  const getStatusText = () => {
    switch(paymentParams.status) {
      case 'success':
        return ('Thank you! Your payment of ₹' + Number(paymentParams.amount) + 
          ' has been received.')
      case 'failed':
        return 'Payment for amount ₹' + Number(paymentParams.amount) + 
          ' failed.'
      case 'cancelled':
        return 'You have cancelled the payment.'
      case 'booking-complete':
        return 'You appointment has been booked'
      default: return '' 
    }
  }

  return (
    <Dialog
      open={shouldOpen}
      onClose={onPaymentModeClose}
      TransitionComponent={Slide}
      TransitionProps={{
        direction: 'up'
      }}
    >
      <DialogContent>
        <Typography
          className={'status-head ' + 
            (isPositiveOutcome ? 'success ' : paymentParams.status)
          }
          variant="h6"
          component="h6"
          gutterBottom
          align="center"
        >
          {getStatusHeading()}
        </Typography>
        <Typography
          className={'status text'}
          variant="body2"
          gutterBottom
          align="center"
        >
          {getStatusText()}
        </Typography>

        <div 
          className={'status icon text center '+ 
            (isPositiveOutcome ? 'success ' : paymentParams.status)
          }
        >
          {
            ['booking-complete', 'success'].includes(paymentParams.status) &&
            <CheckCircleOutlinedIcon />
          }
          {
            !isPositiveOutcome &&
            <ErrorOutlineOutlinedIcon />
          }
        </div>
      </DialogContent>
      <DialogActions className="payment-dialog actions">
          <Button onClick={onPaymentModeClose}>
            Close
          </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PaymentStatusDialog;
