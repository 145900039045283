import { useEffect } from 'react';
import dataManager from '../utils/DataManager';
import { useHistory } from 'react-router-dom';

const paths = dataManager.getPaths();

function useLogout(logoutState) {
    const history = useHistory();

    useEffect(() => {
        if (logoutState.logout) {
            dataManager.clearSessionStorage();
            dataManager.clearLocalStorage();
            history.push(paths.login);
        }
    // eslint-disable-next-line
    }, [logoutState])

    return logoutState
}

export default useLogout;
