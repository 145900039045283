import '../styles/WorkFlow.css';
import React from 'react';
import right from '../icons/right.png'
import arrow from '../icons/arrow.png'
import appointment from '../icons/appointment.png'
import consulting from '../icons/consulting.png'
import {
    Typography,
    makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    workingText: {
        color: "#00aeff",
        fontWeight: "bold",
    },
}))

function WorkFlow() {
    const classes = useStyles();
    return (
        <div className="work-flow-desc">
            <Typography align="left" className={classes.workingText}>
                How it works?
</Typography>
            <div className="work-flow-icons">
                <div>
                    <img alt='View Appointment' src={appointment}></img>
                    <div className="text">View Appointment</div>
                </div>
                <div>
                    <img alt='arrow next step' className="arrow" src={arrow}></img>
                </div>
                <div className="checkin">
                    <img alt='Check-in' src={right}></img>
                    <div className="text">Check-in</div>
                </div>
                <div>
                    <img alt='arrow next step' className="arrow" src={arrow}></img>
                </div>
                <div>
                    <img alt='Begin Consultation' src={consulting}></img>
                    <div className="text">Begin Consultation</div>
                </div>
            </div>
        </div>

    )
}

export default WorkFlow;