import '../styles/Footer.css';
import React from 'react';
import dataManager from '../utils/DataManager';
import {
  Button,
  IconButton
} from '@material-ui/core'
import { useHistory, useLocation} from 'react-router-dom';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import BuildIcon from '@material-ui/icons/Build';

function Footer(props) {

  const paths = dataManager.getPaths();
  const history = useHistory();
  const location = useLocation();

  const loadFaqInNewTab = ()=>{
    let url = window.location.origin + paths.faq;
    window.open(url);
  }

  const showDeviceSettingsPage = () => {
    history.push(paths.deviceTest, {from: 'afterClick'});
  }

  return (
    <div
      position="static"
      className={"footer-bar"}
    >
      <div className='faq-label'>
        {
          location.pathname !== paths.deviceTest &&
          <IconButton
            onClick={showDeviceSettingsPage} 
            className={'settings btn'}
          >
            <BuildIcon />
          </IconButton>
        }
        <Button color="inherit" onClick={loadFaqInNewTab}>FAQs</Button>
      </div>
      <div className='faq-care'>
          <div className='care-no'>
            <HeadsetMicIcon/>&nbsp;
            <a href="tel:+918882599599">8882599599</a>
          </div>
          <div className='care-days'>(Mon - Sat: 10AM - 8PM)</div>
      </div>
    </div>
  )
}

export default Footer;
