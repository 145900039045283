import '../styles/ConfirmSlot.css';
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DocListingCard from './DocListingCard';
import usePatientName from '../hooks/usePatientName';
import moment from 'moment';
import Moment from 'react-moment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EditIcon from '@material-ui/icons/Edit';
import dataManager from '../utils/DataManager';
import { post } from '../utils/Communication';
import constants from '../constants';
import { proceedToRazorPay } from '../utils/PaymentGateway';
import sentryManager from '../utils/SentryManager';

function ConfirmSlot(props) {

  const {
    docDetails,
    patientInfo,
    selectedSlot,
  } = props

  const patientName = usePatientName(patientInfo);
  const history = useHistory();
  const paths = dataManager.getPaths();
  const appointmentType = dataManager.getAppointmentTypes();
  const paymentMode = dataManager.getPaymentModes();

  const [slotDateTime, setSlotDateTime] = useState(null)
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);

  useEffect(() => {
    if (selectedSlot) {
      setSlotDateTime(moment(
        selectedSlot.date + ' ' + selectedSlot.start_time
      ))
    }
  }, [selectedSlot])

  const getPatientGender = () => {
    if (patientInfo.sex.toLowerCase() === 'm')
      return 'Male'
    if (patientInfo.sex.toLowerCase() === 'f')
      return 'Female'
    return '--'
  }

  const getPatientAge = () => {
    if (!patientInfo.age)
      return 0;
    return patientInfo.age;
  }

  const makePayment = () => {
    const dataToSend = {
      doctor_id: docDetails.doctor_id,
      appointment_details: {
        slot: selectedSlot.id,
        type: appointmentType.telemedicine,
        doctor: selectedSlot.doctor,
        payment_mode: paymentMode.razorpay,
        pay_and_book: true
      }
    }

    post(constants.endpoints.razorpayOrderCreate, dataToSend, true)
      .then(res => {
        if ([200, 201].includes(res.status))
          return res.json()
        else if (res.status === 409) {
          res.json().then(result => {
            const msg = result.msg || 'You have already booked this slot';
            alert(msg);
          })
        }
        else {
          alert('Could not proceed to payment.');
          sentryManager.captureMessage(
            'Error creating self booking order - status: ' + res.status +
            ' Data: ' + JSON.stringify(dataToSend)
          )
        }
      })
      .then(result => {
        if (result) {
          proceedToRazorPay(docDetails.full_name, result.order_id,
            result.key_id, patientInfo);
        }
      })
  }

  const bookAppointment = () => {
    const dataToSend = {
      slot: selectedSlot.id,
      type: appointmentType.telemedicine,
      doctor: selectedSlot.doctor
    }

    post(constants.endpoints.getAppointmentInfo, dataToSend, true)
      .then(res => {
        if(res.status === 200)
          return res.json()
        else if (res.status === 409) {
          res.json().then(result => {
            const msg = result.msg || 'You have already booked this slot';
            alert(msg);
          })
        }
        else {
          alert('Could not book appointment please try again.')
          sentryManager.captureMessage(
            'Error creating self booking appointment - status: ' + 
            res.status + ' Data: ' + JSON.stringify(dataToSend)
          )
        }
      })
      .then(result => {
        if (result) {
          history.replace('/payment/booking-complete/amt/no-fee')
        }
      })

  }

  const handleBookAppointment = () => {
    if (!!docDetails.fees)
      makePayment();
    else
      bookAppointment()
  }

  const showCancelConfirm = () => {
    setCancelConfirmOpen(true)
  }

  const cancelPayment = (stateToKeep={}) => {
    let cancelPath = paths.doctorListing;
    const state = history.location.state || null;
    if (state && state.replaceWith)
      cancelPath = state.replaceWith;
    history.replace(cancelPath, stateToKeep);
  }

  const editProfile = () => {
    let replaceWith = paths.doctorListing;
    const state = history.location.state || null;
    if (state && state.replaceWith)
      replaceWith = state.replaceWith;
    history.replace(paths.confirmUserDetails, {
      from: 'afterConfirmSlot',
      replaceWith: replaceWith
    })
  }

  const changeSlot = () => {
    cancelPayment({
      selectedDocId: docDetails.doctor_id
    })
  }

  const getSlotAmount = () => {
    if (!!docDetails.fees)
      return '₹' + Number(docDetails.fees);
    return 'No Booking fee';
  }

  const handleCancelConfirmClose = () => {
    setCancelConfirmOpen(false);
  }

  const onAgreeToCancelPayment = () => {
    setCancelConfirmOpen(false);
    cancelPayment();
  }

  return (
    <>
      <Typography
        className={'list header'}
        display="block" variant="body2"
        align="center">
        Book Appointments
      </Typography>

      <DocListingCard docDetails={docDetails} showBookApt={false}/>
      <Card>
        <CardContent>
          <Typography variant="caption" className={'confirm head'}
            display="block"
          >
            Confirm Details
          </Typography>
          <div className="patient-info">
            <Typography variant="subtitle1" className='patient name'>
              <span>
                {patientName}
              </span>
              <IconButton
                size="small"
                onClick={editProfile}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Typography>
            <div className={'patient-details'}>
              <span className={'confirm-age'}>
                {getPatientAge()} yrs,
              </span>
              <span className={'confirm-gender'}>
                {getPatientGender()}
              </span>
              <span className={'confirm-mobile'}>
                {patientInfo.mobile}
              </span>
            </div>
          </div>
          <div className="appointment-info">
            <div className="confirm-apt-date">
              Appointment on
              <Moment
                date={slotDateTime}
                format={' MMMM DD'}
              ></Moment>
              <Link
                component="button"
                variant="caption"
                className={'change-apt-link'}
                onClick={changeSlot}
              >
                Change
              </Link>
            </div>
            <div className="confirm-apt-time">
              <AccessTimeIcon className={'apt-time icon'} /> 
              <Moment 
                date={slotDateTime}
                format={'HH:mm A'}
              ></Moment>
            </div>
          </div>
          {
            !!docDetails.fees &&
            <div className="amt-info">
              Amount to be paid
              <span className="confirm-fee">{getSlotAmount()}</span>
            </div>
          }

          {
            !!docDetails.fees &&
            <div className="confirm-pay-secure">
              <VerifiedUserIcon className="secure icon" />
              100% Safe and Secure Payments
            </div>
          }

        </CardContent>
        <CardActions
          className={'card-action-cnt'}
        >
          <Button className={'book-apt card-action btn'}
            onClick={handleBookAppointment}
          >
            { 
              !!docDetails.fees &&
              <span>
                Pay&nbsp;&&nbsp;Book
              </span>
            }
            {
              !docDetails.fees &&
              <span>
                Book&nbsp;Appointment
              </span>
            }
          </Button>
          <Divider 
            className={'action divider'}
            orientation="vertical"
            light={true}
            flexItem
          />
          <Button className={'book-apt-cancel card-action btn'}
            onClick={showCancelConfirm}
          >
            Cancel
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={cancelConfirmOpen}
        onClose={handleCancelConfirmClose}
      >
        <DialogContent>
          Are you sure you want to cancel the booking?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onAgreeToCancelPayment}
          >
            Yes
          </Button>
          <Button
            onClick={handleCancelConfirmClose}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConfirmSlot;
