import styles from '../styles/CallRatingDialog.module.css';
import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Slide,
  Zoom,
  withStyles
} from '@material-ui/core';
import { patch, post } from '../utils/Communication';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import dataManager from '../utils/DataManager';
import constants from '../constants';
import sentryManager from '../utils/SentryManager';
import useAdditionalUserDetails from '../hooks/useAdditionalUserDetails';


const StyledRating = withStyles({
  iconFilled: {
    color: '#00aeff',
  },
  iconHover: {
    color: '#00aeff',
  },
})(Rating);

function CallRatingDialog(props) {

  const {
    shouldOpen,
    onReconnectToRoom,
    patientInfo,
    roomDurationMs,
    onCallRatingClose,
    roomAlreadyEnded,
  } = props;

  const additionalDetails = useAdditionalUserDetails(patientInfo);

  const roomStatus = dataManager.getRoomStatus();
  const data = dataManager.getData();
  const appointmentInfo = data.selectedAppointment;
  const appointmentRoomDetails = data.appointmentRoomDetails;

  const [openDialog, setOpenDialog] = useState(false);
  const [ratingVal, setRatingVal] = useState(0);
  const [callDuration, setCallDuration] = useState({
    min: 0,
    sec: 0
  });
  const [sendingFeedback, setSendingFeedback] = useState(false);

  const endRoomTimeout = useRef(null);
  const endedRoom = useRef(false);

  useEffect(() => {
    setOpenDialog(shouldOpen);
    setSendingFeedback(false);
    if (shouldOpen) {
      endRoomTimeout.current = setTimeout(() => {
        sendEndRoomRequest();
      }, constants.autoEndTimeSec * 1000)
    }
    // eslint-disable-next-line
  }, [shouldOpen])

  useEffect(() => {
    if (ratingVal !== 0) {
      clearTimeout(endRoomTimeout.current);
      endRoomTimeout.current = setTimeout(() => {
        sendEndRoomRequest();
        sendFeedback();
      }, constants.sendFeedbackTimeSec * 1000)
    }
    // eslint-disable-next-line
  }, [ratingVal])

  useEffect(() => {
    if (roomDurationMs) {
      const minutes = roomDurationMs / (1000 * 60)
      const seconds = Math.floor((minutes - Math.floor(minutes)) * 60)
      setCallDuration({
        min: Math.floor(minutes),
        sec: seconds
      })
    }
  }, [roomDurationMs])

  const reconnectToRoom = () => {
    if (!roomAlreadyEnded) {
      clearTimeout(endRoomTimeout.current);
      onReconnectToRoom(appointmentInfo, null, null, true);
    } else {
      setOpenDialog(false);
    }
  }

  const sendEndRoomRequest = () => {
    if (!endedRoom.current) {
      if (appointmentRoomDetails && appointmentRoomDetails.unique_name &&
        !roomAlreadyEnded
      ) {
        const endRoomPatchUrl =
          constants.endpoints.endRoom + appointmentRoomDetails.unique_name + '/';
        const dataToSend = {
          status: roomStatus.ended
        }
        patch(endRoomPatchUrl, dataToSend, true)
          .then(response => {
            // close dialog irrespective of the server response
            setOpenDialog(false);
            if (response.status === 200)
              return true
            else {
              sentryManager.captureMessage (
                'Could not end room through patch - status ' + response.status +
                ' Data: ' + JSON.stringify(dataToSend)
              )
              return true
            }
          })
          .catch(err => {
            // close dialog irrespective of the server response
            setOpenDialog(false);
            sentryManager.captureMessage (
              'Error occured when sending end room request - Data: ' +
              JSON.stringify(dataToSend)
            )
          })
      } else {
         setOpenDialog(false);
      }
      endedRoom.current = true;
    }
  }

  const sendFeedback = () => {
    if (ratingVal > 0) {
      setSendingFeedback(true)
      const dataToSend = {
        description: '',
        content_type: "appointment",
        object_id: appointmentInfo.id,
        extras: {
          rating: ratingVal,
          patient: appointmentInfo.doc_patient,
        },
        reasons: [8]
      }

      post(constants.endpoints.feedback, dataToSend, true)
        .then(res => {
          if (res.status === 200)
            return res.json()
          else {
            sentryManager.captureMessage('Error sending rating - status ' +
              res.status + ' Data: ' + JSON.stringify(dataToSend)
            )
          }
        })
    }
  }

  const handleRatingChange = (event, value) => {
    clearTimeout(endRoomTimeout.current);
    setRatingVal(value);
  }

  const handleCloseAction = () => {
    clearTimeout(endRoomTimeout.current);
    sendEndRoomRequest();
    // setOpenDialog(false);
  }

  if (!appointmentInfo)
    return null;

  return (
    <Dialog
      open={openDialog}
      onExiting={onCallRatingClose}
      TransitionComponent={Slide}
      TransitionProps={{
        direction: 'up'
      }}
    >
      <DialogContent>

        <Zoom in={sendingFeedback} mountOnEnter unmountOnExit>
          <>
          <Typography variant="body1"
            className= {styles.ratingComplete + ' ' + styles.header}
            align="center"
          >
            Thank you for your feedback!
          </Typography>
          </>
        </Zoom>

        <Typography variant="body1"
          align="center"
          display="block"
          gutterBottom
        >
          {
            !additionalDetails.isMr &&
            <>
              Your consultation with Dr. {appointmentInfo.doc_name} is
              completed
            </>
          }
          {
            additionalDetails.isMr &&
            <>
              Your video call with Dr. {appointmentInfo.doc_name} is
              completed
            </>
          }
        </Typography>
        <Typography variant="body2"
          align="center"
          display="block"
          className= {styles.call + ' ' + styles.duration}
        >
          Duration: {callDuration.min} min(s) {callDuration.sec} sec(s)
        </Typography>
        {
          !sendingFeedback &&
          <>
            <div className={styles.reconnectContainer + ' text center '}>
              <Typography variant="body2"
                display="inline"
                gutterBottom
              >
                Network issue?
              </Typography>
              <Button
                variant="contained"
                className={styles.reconnectBtn}
                onClick={reconnectToRoom}
                size="small"
                disabled={roomAlreadyEnded || sendingFeedback}
              >
                Re-connect
              </Button>
            </div>
            <div className={styles.ratingContainer}>
              <Typography variant="caption"
                align="center"
                display="block"
                gutterBottom
              >
                How was the video & audio?
              </Typography>
              <div className={'text center'}>
                <StyledRating
                  name="rating"
                  defaultValue={ratingVal}
                  precision={1}
                  onChange={handleRatingChange}
                  size="large"
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
                <div className={styles.ratingInfo}>
                  <span>Very Bad</span>
                  <span>Very Good</span>
                </div>
              </div>
            </div>
          </>
        }
      </DialogContent>
      {
        !sendingFeedback &&
        <DialogActions>
          <Button onClick={handleCloseAction}>
            Close
          </Button>
        </DialogActions>
      }
    </Dialog>
  )
}

export default CallRatingDialog;
