const prepareAndGetSteps = (isStandalone) => {
    const stepsToPerform = [
        {
            label: 'Checking Audio/Video Permissions',
            extra: '',
            completed: false,
            error: false,
            content: 'Please Wait',
            showLoader: true,
            forReconnect: true,
        },
        {
            label: 'Checking your Video',
            extra: '',
            completed: false,
            error: false,
            content: 'Please Wait',
            showLoader: true,
            forReconnect: false,
        },
        {
            label: 'Checking your Audio',
            extra: '',
            completed: false,
            error: false,
            content: 'Please Wait',
            showLoader: true,
            forReconnect: false,
        },
        {
            label: isStandalone ? 'Completing Tests' : 'Proceeding To Room',
            extra: '',
            completed: false,
            error: false,
            content: isStandalone ? 'All Tests Passed Successfully' : 'Joining room...',
            showLoader: true,
            forReconnect: true,
        }
    ]
    return stepsToPerform;
}



export const getStepsToPerform = ({isReconnect, isStandalone}) => {
    const stepsToPerform = prepareAndGetSteps(isStandalone);
    if (isReconnect)
        return stepsToPerform.filter(step => step.forReconnect);
    return stepsToPerform;
}
