import constants from '../constants';
import dataManager from './DataManager'

/**
 * 
 * @param {string} endpoint 
 * @param {Object} queryParams 
 */
export const get = (endpoint, queryParams=null, useAuth=false) => {
    let url = new URL(constants.apiUrl + endpoint);
    let fetchOptions = {};
    if (queryParams)
        url.search = new URLSearchParams(queryParams).toString();

    if (useAuth) {
        let authToken = JSON.parse(dataManager.getFromSessionStorage('authToken'))
        if (!authToken) {
            authToken = JSON.parse(dataManager.getFromLocalStorage('authToken'))
        }
        fetchOptions = {
            headers: {
                'Authorization': 'Token ' + authToken.token
            }
        }
        return fetch(url, fetchOptions)
    }
    return fetch(url);
}

export const post = (endpoint, data, useAuth=false) => {
    const url = constants.apiUrl + endpoint;
    let fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    }

    if (useAuth) {
        let authToken = JSON.parse(dataManager.getFromSessionStorage('authToken'))
        if (!authToken) {
            authToken = JSON.parse(dataManager.getFromLocalStorage('authToken'))
        }
        fetchOptions.headers['Authorization'] = 'Token ' + authToken.token
    }

    return fetch(url, fetchOptions);
}

export const patch = (endpoint, data, useAuth=true) => {
    const url = constants.apiUrl + endpoint;
    let fetchOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    }

    if (useAuth) {
        let authToken = JSON.parse(dataManager.getFromSessionStorage('authToken'))
        if (!authToken) {
            authToken = JSON.parse(dataManager.getFromLocalStorage('authToken'))
        }
        fetchOptions.headers['Authorization'] = 'Token ' + authToken.token
    }

    return fetch(url, fetchOptions);
}
