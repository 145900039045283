class DataManager {
    constructor() {
        if (!DataManager.dataManager) {
            this.data = {}

            // WILL BE accessible in this.data.appointmentRoomDetails
            // Details from the /appointment_room call
            this.appointmentRoomDetails = {
                joininig_access_token: null,
                joininig_identity: null,
                participant_count: null,
                sid: null,
                unique_name: null,
            }

            this.pages = {
                landing: 'landing',
                otp: 'otp',
                appointments: 'appointments',
                room: 'room',
                faq: 'faq',
                tnc: 'tnc',
                uploadreport:'uploadreport',
                deviceTest: 'devicetest',
            }

            this.paths = {
                home: '/',
                login: '/login',
                otp: '/otp',
                faq: '/faq',
                tnc: '/tnc',
                deviceTest: '/devicetest',
                room: '/room',
                uploadReport: '/uploadreport',
                paymentStatus: '/payment/:status/amt/:amount',
                bookApts: '/book-appointment',
                doctorListing: '/doctor-listing',
                confirmSlot: '/doctor-listing/confirm-slot',
                confirmUserDetails: '/check-your-info',
                doctorListingSpecific: '/book/:doctor',
                receipt: '/r/:receipt',
            }

            this.roomStatus = {
                ended: 2
            }
            this.paymentTypes = {
                upi: 0,
                paytm: 1,
                bank: 2,
                paypal: 3,
                0: 'UPI',
                1: 'PayTM',
                2: 'Bank',
                3: 'PayPal'
            }

            this.paymentModes = {
                cash: 0,
                razorpay: 1,
            }

            this.paymentStatus = {
                new: 0,
                success: 1,
                failed: 2,
                cancelled: 3,
            }

            this.consultationStatus = {
                'NEW': 0,
                'PATIENT_JOINED': 1,
                'STARTED': 2,
                'FINISHED': 3
            }

            this.consultationCancelledBy = {
                scheduler: 0,
                doctor: 1,
                patient: 2
            }

            this.appointmentType = {
                regular: 0,
                telemedicine: 1
            }

            // prevent reload to these pages.
            this.preventReloadFrom = [
                this.paths.room,
                this.paths.uploadReport,
                this.paths.deviceTest,
                this.paths.confirmSlot,
                this.paths.otp,
            ]

            this.inAppNavigationPages = [
                this.paths.room,
                this.paths.uploadReport,
                this.paths.confirmSlot,
                this.paths.confirmUserDetails
            ]

            // Used to check state in PrivateRouting.
            this.inAppNavigationStates = [
                'afterUploadReportSelect',
                'afterRoomCheckinClick',
                'afterListingPage',
                'afterConfirmSlot'
            ]

            this.participantVideoSize = {
                small: 0,
                medium: 1,
                large: 2,
            }

            this.noFooterPages = [
                this.paths.faq,
                this.paths.room,
                this.paths.uploadReport,
            ]

            this.countryList = [
                { code: 'ID', label: 'Indonesia', phone: '62' },
                { code: 'IN', label: 'India', phone: '91' }
            ]

            DataManager.dataManager = this
        }
        return DataManager.dataManager;
    }

    getData() {
        return this.data;
    }

    getPages() {
        return this.pages;
    }

    getPaths() {
        return this.paths;
    }

    getConsultationStatus() {
        return this.consultationStatus;
    }

    getConsultationCancelledBy() {
        return this.consultationCancelledBy;
    }

    getLastPage() {
        return this.lastPage;
    }

    getPaymentTypes() {
        return this.paymentTypes;
    }

    getPaymentModes() {
        return this.paymentModes;
    }

    getPaymentStatus() {
        return this.paymentStatus;
    }

    getAppointmentTypes = () => {
        return this.appointmentType;
    }

    getRoomStatus = () => this.roomStatus;

    getPreventReloadPages = () => this.preventReloadFrom;
    getInAppNavigationPages = () => this.inAppNavigationPages;
    getInAppNavigationStates = () => this.inAppNavigationStates;
    getNoFooterPages = () => this.noFooterPages;

    getParticpantVideoSize = () => this.participantVideoSize;

    getCountryList = () => this.countryList

    countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) =>
                    String.fromCodePoint(char.charCodeAt(0) + 127397)
                )
            : isoCode;
    }

    storeToSessionStorage(key, value) {
        if (value.constructor !== String)
            value = JSON.stringify(value)
        sessionStorage.setItem(key, value);
    }

    getFromSessionStorage(key) {
        return sessionStorage.getItem(key);
    }

    clearKeyFromSessionStorage(key) {
        return sessionStorage.removeItem(key);
    }

    storeToLocalStorage(key, value) {
        if (value.constructor !== String)
            value = JSON.stringify(value)
        localStorage.setItem(key, value);
    }

    getFromLocalStorage(key) {
        return localStorage.getItem(key);
    }

    clearKeyFromLocalStorage(key) {
        return localStorage.removeItem(key);
    }

    clearLocalStorage() {
        return localStorage.clear();
    }

    clearSessionStorage() {
        return sessionStorage.clear();
    }

}

const dataManager = new DataManager();

Object.freeze(dataManager);

export default dataManager;
