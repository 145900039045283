import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import dataManager from '../utils/DataManager';

const paths = dataManager.getPaths();

function useShowPaymentDialog() {

    const [showPaymentStatus, setShowPaymentStatus] = useState(false);
    const [statusParams, setStatusParams] = useState({
        status: null,
        amount: null
    });

    const match = useRouteMatch()

    useEffect(() => {
        if (match.path === paths.paymentStatus) {
            setShowPaymentStatus(true);
            setStatusParams(match.params);
        }
        return () => {
            setShowPaymentStatus(false);
            setStatusParams({
                status: null,
                amount: null
            });
        }
    }, [match])

    return [showPaymentStatus, statusParams];
}

export default useShowPaymentDialog;
