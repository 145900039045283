import '../styles/DoctorsListingPage.css';
import React, { useState, useEffect } from 'react';
import DocListingCard from './DocListingCard';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useRouteMatch, useLocation, Redirect } from 'react-router-dom';
import dataManager from '../utils/DataManager';
import { get } from '../utils/Communication';
import constants from '../constants';
import sentryManager from '../utils/SentryManager';
import useAdditionalUserDetails from '../hooks/useAdditionalUserDetails';

function DoctorsListingPage(props) {


  const {
    onSlotSelected,
    patientInfo,
  } = props;

  const paths = dataManager.getPaths();
  const match = useRouteMatch();
  const additionalDetails = useAdditionalUserDetails(patientInfo);
  const location = useLocation();

  const [fetchingDocList, setFetchingDocList] = useState(true)
  const [docListErrorTxt, setDoclistErrorText] = useState(
    'Could not find doctors linked to your account.'
  )
  const [docList, setDocList] = useState([])

  useEffect(() => {
    if (patientInfo && !patientInfo.error && !additionalDetails.isMr) {
      setFetchingDocList(true);
      let dataToSend = null;
      let doctorListPromises = []

      if (match.path === paths.doctorListingSpecific) {
        let uniqueDoctor = match.params.doctor;

        // NOTE: Patch for ignoring all chars after the ending numbers
        // If url contains anything after numbers then this give wrong doc id
        if (/.+\d+/.test(uniqueDoctor))
          uniqueDoctor = uniqueDoctor.match(/.+\d+/)[0]

        storeUniqueDoctorToLS(uniqueDoctor)
        setDoclistErrorText(
          'It seems you are accessing incorrect Booking link, please check ' +
          'your message for correct link or ask your Doctor to send again.'
        )
        dataToSend = {
          unique_name: uniqueDoctor
        }
      } else {
        const storedDocs = getUniqueDocsFromLS();
        if (storedDocs.length > 0) {
          dataToSend = {
            unique_name: storedDocs
          }
          doctorListPromises.push(
            get(constants.endpoints.doctorsList, dataToSend, true)
              .then(handleDoctorListResponse)
          )
          dataToSend = null;
        }
      }

      doctorListPromises.push(
        get(constants.endpoints.doctorsList, dataToSend, true)
              .then(handleDoctorListResponse)
      )

      Promise.all(doctorListPromises)
        .then(handleDoctorListFromServer)

    } else if (patientInfo && patientInfo.error) {
      setFetchingDocList(false);
    }
  // eslint-disable-next-line
  }, [match.path, additionalDetails, patientInfo])

  if (patientInfo && additionalDetails.isMr) {
    return (
      <Redirect
        to={paths.home}
      />
    )
  }

  const handleDoctorListResponse = (res, dataToSend) => {
    if (res.status === 200)
      return res.json()
    else {
      setFetchingDocList(false);
      sentryManager.captureMessage(
        'Failed to get Doctor List - status: ' + res.status + 
        ' Data: ' + JSON.stringify(dataToSend)
      )
      return [];
    }
  }

  const handleDoctorListFromServer =
    ([docListFromFirstCall, docListFromSecondCall]) => {

      let docListFromServer = [...docListFromFirstCall]
      if (docListFromSecondCall) {
        // remove common entries
        docListFromSecondCall = docListFromSecondCall.filter(doc => {
          return docListFromServer.findIndex((firstCallDoc) => {
            return firstCallDoc.doctor_id === doc.doctor_id
          }) === -1
        })
        docListFromServer = docListFromServer.concat(docListFromSecondCall)
      }

      if (docListFromServer) {
        const state = location.state || null;
        let selectedDocId = null;
        if (state && state.selectedDocId) {
          selectedDocId = state.selectedDocId
        }
        docListFromServer.forEach(doc => {
          doc.wasSelected = selectedDocId === doc.doctor_id
        })
        setDocList(docListFromServer)
        setFetchingDocList(false);
      }
    }

  const getUniqueDocsFromLS = () => {
    let storedDocs = dataManager
      .getFromLocalStorage('storedDocs_' + patientInfo.id) || [];
    try {
      storedDocs = JSON.parse(storedDocs);
    } catch (e) {
      storedDocs = []
    }
    return storedDocs
  }

  const storeUniqueDoctorToLS = (uniqueName) => {
    const keyName = 'storedDocs_' + patientInfo.id;
    let storedDocs = dataManager.getFromLocalStorage(keyName) || [];
    try {
      storedDocs = JSON.parse(storedDocs);
    } catch (e) {
      storedDocs = []
    }
    if (!storedDocs.includes(uniqueName)) {
      storedDocs.push(uniqueName)
      dataManager.storeToLocalStorage(keyName, JSON.stringify(storedDocs))
    }
  }

  const resetWasSelectedState = () => {
    setDocList(oldDocList => {
      const newList = []
      oldDocList.forEach(doc => {
        doc.wasSelected = false;
      })
      return newList.concat(oldDocList)
    })
  }

  return (
    <div>
      <Typography
        className={'list header'}
        display="block" variant="body2"
        align="center">
        Book Appointment
      </Typography>
      { !fetchingDocList && docList.length > 0 &&
        docList.map(doc => (
          <DocListingCard key={doc.doctor_id}
            docDetails={doc}
            onSlotSelected={onSlotSelected}
            showBookApt={true}
            onResetWasSelectedState={resetWasSelectedState}
          />
        ))
      }
      {
        !fetchingDocList && docList.length === 0 &&
        <Typography
          variant="h5"
          component="h5"
        >
          {docListErrorTxt}
        </Typography>
      }
      {
        fetchingDocList &&
        <>
          <Skeleton height={200} animation="wave" />
          <Skeleton height={200} animation="wave" />
          <Skeleton height={200} animation="wave" />
        </>
      }
    </div>
  )
}

export default DoctorsListingPage;
