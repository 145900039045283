import { makeStyles } from '@material-ui/core';

const commonStyles = makeStyles({
    colorBlue: {
        color: '#00aeff'
    },
    bgColorBlue: {
        background: '#00aeff',
        color: '#fff'
    },
    lightGrey: {
        color: '#7b7171'
    },
    doxperLanding: {
        fontWeight: 'bold',
        color: '#00aeff'
    },
    welcomeStyle: {
        fontSize: '22px'
    },
    doxperVideoTitle: {
        color: "#00aeef",
        fontWeight: 'bold',
        marginBottom: '1em'
    },
})

export default commonStyles
