import { useState, useEffect } from 'react';

function useAdditionalUserDetails(patientInfo) {
    const [additionalUserDetails, setAdditionalUserDetails] = useState({
        isMr: (patientInfo && !patientInfo.error) ? patientInfo.is_mr : false,
    })

    useEffect(() => {
        if (patientInfo && !patientInfo.error) {
            setAdditionalUserDetails({
                isMr: patientInfo.is_mr,
            })
        }
    }, [patientInfo])

    return additionalUserDetails;
}

export default useAdditionalUserDetails;
