import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import dataManager from '../utils/DataManager';

const preventReloadFrom = dataManager.getPreventReloadPages();

function useReplaceState() {
    let location = useLocation();
    let history = createBrowserHistory();
    useEffect(() => {
        if (preventReloadFrom.includes(location.pathname)) {
            const state = location.state || null;
            if (state && state.from){
                state.from = null
                history.replace(location.pathname, state);
            }
        }
        // eslint-disable-next-line
    }, [location]);
}

export default useReplaceState;
