import React from 'react';
import DeviceTestingPage from './DeviceTestingPage';


function DeviceTestStandalone() {

    const onGotStream = () => {
        
    }

    const onHideDeviceTest = () => {
    
    }

    return (
        <DeviceTestingPage onGotStream={onGotStream} 
            onHideDeviceTest={onHideDeviceTest}
            isStandalone={true}
        />
    )
}

export default DeviceTestStandalone;
