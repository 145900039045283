import '../styles/Dashboard.css';
import React, { useState, useEffect } from 'react';
import MenuBar from './MenuBar';
import {
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  Snackbar,
} from '@material-ui/core';
import MuiAlertWrapper from './MuiAlertWrapper';
import { get } from '../utils/Communication';
import sentryManager from '../utils/SentryManager';
import constants from '../constants';
import dataManager from '../utils/DataManager';
import useLogout from '../hooks/useLogout';

function Dashboard(props) {

  const {
    routes,
  } = props

  const paths = dataManager.getPaths();
  const data = dataManager.getData();
  const history = useHistory();
  const location = useLocation();

  const [logoutState, setLogoutState] = useState({
    logout: false,
  })
  useLogout(logoutState);

  const [snackBarState, setSnackBarState] = useState({
    open: false,
    severity: 'info',
    msg: ''
  });
  const [patientInfo, setPatientInfo] = useState(null);
  const [selectedItems, setSelectedItems] = useState({
    docDetails: null,
    selectedSlot: null,
  })


  useEffect(() => {
    get(constants.endpoints.getPatientInfo, null, true)
      .then(
        response => {
          if (response.status === 200) {
            return response.json()
          } else {
            const dummyPatientInfo = { error: 'Failed to get patient info.' }
            setPatientInfo(dummyPatientInfo)
            response.json().then(res => {
              sentryManager.captureMessage(
                'Error with getting patient info: ' + JSON.stringify(res)
              )
            }).catch(err => {
              sentryManager.captureMessage(
                "Could not get patient info - status: " + response.status + 
                "Redirected to update deatils? - " + Boolean(data.mobile)
              );
            })
            if (response.status === 400) {
              if (data.mobileNumber) {
                setPatientInfo({
                  ...dummyPatientInfo,
                  first_name: '',
                  last_name: '',
                })
                history.push(paths.confirmUserDetails, {
                  mobile: data.mobileNumber,
                  replaceWith: history.location.pathname,
                })
              } else {
                logout();
              }
            }
          }
        }
      )
      .then(result => {
        if (!result)
          return;
        setPatientInfo(result);
        sentryManager.setUser(result.mobile);
      })
    // eslint-disable-next-line
  }, [])

  const logout = () => {
    setLogoutState({
      logout: true,
    })
  }

  const onSlotSelected = (selectedDoc, selectedSlot, replaceState = false,
    replaceUrl = location.pathname
  ) => {
    setSelectedItems({
      docDetails: selectedDoc,
      selectedSlot: selectedSlot,
    })
    if (!replaceState)
      history.push(paths.confirmSlot, {
        from: 'afterListingPage',
        replaceWith: replaceUrl
      });
    else
      history.replace(paths.confirmSlot, {
        from: 'afterListingPage',
        replaceWith: replaceUrl
      });
  }

  const onPatientInfoUpdated = (newPatientInfo) => {
    setPatientInfo(newPatientInfo);
  }

  const onNeedSnackBar = (msg, type) => {
    setSnackBarState({
      open: true,
      severity: type,
      msg: msg
    })
  }

  const handleSnackbarClose = () => {
    setSnackBarState(oldState => {
      const newState = {...oldState}
      newState.open = false;
      return newState;
    });
  }

  const getExtraProps = (path) => {
    let extraProps = {
      patientInfo: patientInfo,
      onNeedSnackBar: onNeedSnackBar,
    };
    // not doing anything special for list appointment path
    if (path.constructor !== Array) {
      if ([paths.confirmSlot, paths.confirmUserDetails].includes(path))
        extraProps = { ...extraProps, ...selectedItems }

      if(paths.confirmUserDetails === path) {
        extraProps = {
          ...extraProps,
          onSlotSelected: onSlotSelected,
          onPatientInfoUpdated: onPatientInfoUpdated,
        }
      }
    } else {
      if (path.includes(paths.doctorListing) ||
        path.includes(paths.doctorListingSpecific)
      )
        extraProps = {
          ...extraProps,
          onSlotSelected: onSlotSelected,
        }
    }
    return extraProps
  }

  return (
    <>
      <MenuBar
        patientInfo={patientInfo}
      />
      <div className={'dashboard-container'}>
        {
          routes.map((route, idx) => {
            const extraProps = getExtraProps(route.path)
            return <Route exact
              key={route.path + idx}
              path={route.path}
              render={(props) => (
                <route.component {...route.props} routes={route.routes}
                  {...extraProps}
                />
              )}
            />
          })
        }
      </div>
      <Snackbar open={snackBarState.open} autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlertWrapper 
          onClose={handleSnackbarClose} 
          severity={snackBarState.severity}
        >
          {snackBarState.msg}
        </MuiAlertWrapper>
      </Snackbar>
    </>
  )
}

export default Dashboard;
