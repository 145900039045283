import '../styles/ConsultationRoom.css'
import React, { useEffect, useState, useRef } from 'react';
import { post } from '../utils/Communication'
import constants from '../constants'
import VIDEO from 'twilio-video'
import dataManager from '../utils/DataManager';
import Draggable from 'react-draggable';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  CircularProgress,
  Typography,
  Slide,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert'
import CallEndIcon from '@material-ui/icons/CallEnd';
import MicOffIcon from '@material-ui/icons/MicOff';
// TODO: remove the disable linter once flip camera is working on Apps.
// eslint-disable-next-line
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import StopScreenShareOutlinedIcon 
  from '@material-ui/icons/StopScreenShareOutlined';
import sentryManager from '../utils/SentryManager';
import DeviceTestingPage from './DeviceTestingPage';
import useAdditionalUserDetails from '../hooks/useAdditionalUserDetails';
import WhichBrowser from 'which-browser';
import ParticipantVideo from './ParticipantVideo';

const BROWSER_INFO = new WhichBrowser(navigator.userAgent);
const isDesktop = BROWSER_INFO.device.type.toLowerCase() === 'desktop';
const participantVideoSize = dataManager.getParticpantVideoSize();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ConsultationRoom(props) {

  const paths = dataManager.getPaths();
  const history = useHistory();
  const location = useLocation();
  const data = dataManager.getData();
  const isReconnect = data.selectedAppointment.isReconnect;

  const backToHome = ({connectedToRoom=false, roomEnded=false}) => {
    let roomDuration = 0;
    if (callDurationMs.current) {
      roomDuration = Date.now() - callDurationMs.current;
    }
    history.replace(paths.home, {
      from: 'fromLeavingRoom',
      connectedToRoom: connectedToRoom,
      roomDurationMs: roomDuration,
      roomAlreadyEnded: roomEnded,
    });
  }

  const [patientInfo, setPatientInfo] = useState(null);
  const additionalDetails = useAdditionalUserDetails(patientInfo);

  const [doctorName, setDoctorName] = useState('');
  const [participantAudioMute, setParticipantAudioMute] = useState(false);
  const [participantVideoMute, setParticipantVideoMute] = useState(false);
  const participantsInRoom = useRef(1);
  const [token, setToken] = useState(null);
  const [isAudioMute, setAudioMute] = useState(false);
  const [isVideoDisabled, setVideoDisabled] = useState(false);
  const [waitingForDoctor, setWaitingForDoctor] = useState(true);
  const [isInitalConnect, setIsInitialConnect] = useState(true);
  const [waitingMsg, setWaitingMsg] = useState('Getting room details...');
  const [isConfirmationDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmDialogText, setConfirmDialogText] = useState('');

  const [isAlertDialoagOpen, setIsAlertDialoagOpen] = useState(false);
  const [alertDialogTitle, setAlertDialogTitle] = useState('');
  const [alertDialogText, setAlertDialogText] = useState('');
  const [onAlertDiaglogClick, setOnAlertDiaglogClick] = useState(() => {
    return () => { setIsAlertDialoagOpen(false) }
  });

  const [isRetryDialogOpen, setIsRetryDialogOpen] = useState(false);
  const [retryDialogTitle, setRetryDialogTitle] = useState('');
  const [retryDialogText, setRetryDialogText] = useState('');
  const [onRetryDiaglogYes, setOnRetryDiaglogYes] = useState(() =>{
    return () => {console.log('no action on confirm')}
  });
  const [onRetryDiaglogNo, setOnRetryDiaglogNo] = useState(() => {
    return () => {
      setIsRetryDialogOpen(false);
      backToHome({});
    }
  });

  const [tryGettingTokenAgain, setTryGettingTokenAgain] = useState({
    try: false
  });
  const [tryRoomConnAgain, setTryRoomConnAgain] = useState({
    try: false
  });
  const [manualLeave, setManualLeave] = useState(false);
  const [showDeviceTesting, setShowDeviceTesting] = useState(true)
  const [localAvStream, setLocalAvStream] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [snackbarHideDuration, setSnackbarHideDuration] = useState(null);
  const [screenShareOpen, setScreenShareOpen] = useState(false);
  const [screenShareStream, setScreenShareTrack] = useState(null);
  const [participantVideoStream, setParticipantVideoStream] = useState(null);
  const [participantAudioStream, setParticipantAudioStream] = useState(null);
  const twilioRoom = useRef(null);
  const facingMode = useRef('user');
  const ownVideoRef = useRef(null);
  const ownAudioRef = useRef(null);
  const screenShareVideoRef = useRef(null);
  const screenShareAudioRef = useRef(null);
  const autoLeaveRoomTimeout = useRef(null);
  const participantDisconnTimeout = useRef(null);
  const participantVideoRef = useRef(null);
  const participantAudioRef = useRef(null);
  const callDurationMs = useRef(0);

  useEffect(()=> {
    const data = dataManager.getData();
    if (data.selectedAppointment) {
      const appointment_id = data.selectedAppointment.id;
      sentryManager.setTag('appointment', appointment_id);
    }
  },[])

  useEffect(() => {
    return () => {
      sentryManager.captureMessage(`Patient left the consulation room page`);
    }
  })

  useEffect(()=> {
    const unloadEvent = (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
      // const data = dataManager.getData();
      // dataManager.storeToSessionStorage('ongoingApt', data.selectedAppointment)
      sentryManager.captureMessage(`Patient left the page while consultation was open`);
    }
    window.addEventListener('pagehide', unloadEvent)
    return () => {
      window.removeEventListener('pagehide', unloadEvent);
    }
  }, [])

  useEffect(()=> {
    // usefull for clearing data after page hidden;
    dataManager.clearKeyFromSessionStorage('ongoingApt')
  })

  useEffect(() => {
    const state = location.state || null;
    if (state && state.patientInfo) {
      setPatientInfo(state.patientInfo);
    }
  }, [location])

  useEffect(() => {
    if (!showDeviceTesting) {
      const data = dataManager.getData();
      const dataToSend = {
        appointment_id: data.selectedAppointment.id,
        patient_id: data.selectedAppointment.doc_patient
      }
      if (data.selectedAppointment.doc_name)
        setDoctorName(data.selectedAppointment.doc_name)
      post(constants.endpoints.getConnectionToken, dataToSend, true)
        .then(res => {
          if (res.status === 200)
            return res.json();
          else if (res.status === 402) {
            setIsInitialConnect(false);
            setAlertDialogText('Could not verify payment details!');
            setAlertDialogTitle('Payment Verification failed');
            setIsAlertDialoagOpen(true);
            setOnAlertDiaglogClick(() => {
              return () => {
                setIsAlertDialoagOpen(false);
                backToHome({});
              }
            });
            sentryManager.captureMessage(
              'Could not fetch token - status: ' + res.status + 
              ' Data: ' + JSON.stringify(dataToSend)
            );
          } else if (res.status === 400) {
            res.json()
              .then(response => {
                if (response &&
                  response.error === 'Sorry Consultation was completed.') {
                  setIsInitialConnect(false);
                  setAlertDialogText(
                    additionalDetails.isMr ?
                      'Sorry, this video call has been ended.' :
                      'Sorry, this consultation has been ended.'
                  );
                  setAlertDialogTitle(
                    additionalDetails.isMr ?
                      'Video Call Ended' : 'Consultation Ended'
                  );
                  setIsAlertDialoagOpen(true);
                  setOnAlertDiaglogClick(() => {
                    return () => {
                      setIsAlertDialoagOpen(false);
                      backToHome({roomEnded: true});
                    }
                  });
                  sentryManager.captureMessage(
                    'Tried to join room after it was ended - status: ' +
                    res.status + ' Data: ' + JSON.stringify(dataToSend)
                  );
                } else {
                  handleFetchTokenError(res, dataToSend)
                }
              })
              .catch(err => {
                handleFetchTokenError(res, dataToSend)
              })
          }
          else {
            return handleFetchTokenError(res, dataToSend);
          }
        })
        .then(
          (result) => {
            if (result) {
              if (tryGettingTokenAgain.try) {
                sentryManager.captureMessage(`Successfully Fetched token 
                  after retrying`);
              }
              // eslint-disable-next-line
              let data = dataManager.getData();
              data.appointmentRoomDetails = result;
              sentryManager.setTag('latest_room', result.unique_name)
              setToken(result.joininig_access_token)
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          err => {
            console.log('Err with Token request: ', err)
          }
        )
    }
  // eslint-disable-next-line
  }, [showDeviceTesting, tryGettingTokenAgain])

  useEffect(() => {
    if (token !== null) {
      setWaitingMsg('Connecting to room...')
      VIDEO.connect(token, {
        tracks: localAvStream.getTracks(),
      })
        .then((room) => {
          if (tryRoomConnAgain.try) {
            sentryManager.captureMessage(`Successfully connected to room after 
              retrying due to twilio error`)
          }
          sentryManager.setBreadcrumb({
            msg: 'Connected to room',
            category: 'room',
          })
          twilioRoom.current = room;
          onConnectToRoom();
        }, error=> {
            showRetryDialog(`Network issue occured`, true);
            sentryManager.captureMessage('Twilio error: ' + error.toString())
        })
    }
    return () => {
      if (twilioRoom && twilioRoom.current && 
          twilioRoom.current.localParticipant) {
        const localTracks = twilioRoom.current.localParticipant.tracks;
        if (localTracks) {
          localTracks.forEach(localTrack => {
            if (localTrack.kind !== 'data')
              localTrack.track.stop()
          })
        }
        twilioRoom.current.disconnect();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, tryRoomConnAgain])

  useEffect(()=> {
    if (!showDeviceTesting && ownVideoRef.current)
      ownVideoRef.current.srcObject = localAvStream;
    return () => {
      if (localAvStream)
        localAvStream.getTracks().forEach(track => track.stop())
    }
  // eslint-disable-next-line
  },[showDeviceTesting])

  useEffect(() => {

    const currScreenShareVideoRef = screenShareVideoRef.current
    const currScreenShareAudioRef = screenShareAudioRef.current

    const trackEndedEvent = () => {
      setScreenShareOpen(false)
    }

    if (screenShareStream && screenShareStream.constructor === MediaStream) {
      let trackPromises = [];
      screenShareStream.getTracks().forEach(track => {
        let twilioTrackObj = VIDEO.LocalVideoTrack;
        if (track.kind === 'audio') {
          twilioTrackObj = VIDEO.LocalAudioTrack;
        }

        const screenShareTwilioTrack = new twilioTrackObj(track, {
          name: 'screenshare_' + track.kind
        })

        if (twilioRoom.current) {
          trackPromises.push(
            twilioRoom.current.localParticipant
              .publishTrack(screenShareTwilioTrack)
              .then(() => screenShareTwilioTrack)
          )
        }
        track.addEventListener('ended', trackEndedEvent)
      })

      Promise.all(trackPromises)
        .then((publishedTracks) => {
          if (screenShareVideoRef && screenShareVideoRef.current) {
            screenShareVideoRef.current.srcObject = participantVideoStream;
            screenShareAudioRef.current.srcObject = participantAudioStream;
            sentryManager.setBreadcrumb({
              msg: 'Published screenshare track and set the track into view',
              category: 'room',
            })
          }
          setScreenShareOpen(true)
        })
        .catch((err) => {
          sentryManager.captureMessage(
            'Error publishing share screen tracks - error: ' + err.message
          )
          setScreenShareOpen(false)
        })
    }

    return () => {
      if (screenShareStream && screenShareStream.constructor === MediaStream) {
        screenShareStream.getTracks().forEach(track => {
          track.stop();
          sentryManager.setBreadcrumb({
            msg: 'Stopped screenshare track',
            category: 'room',
            kind: track.kind
          })
        })

        
        if (twilioRoom.current) {
          const videoTracks = twilioRoom.current.localParticipant.videoTracks
          if (videoTracks) {
            videoTracks.forEach(videoTrack => {
              const track = videoTrack.track;
              if (
                ['screenshare_video', 'screenshare_audio'].includes(track.name)
              ) {
                  track.stop();
                  sentryManager.setBreadcrumb({
                    msg: 'Stopped screenshare track on twillio',
                    category: 'room',
                    kind: track.kind
                  })
                  twilioRoom.current.localParticipant.unpublishTrack(track)
                    .then(() => {
                      sentryManager.setBreadcrumb({
                        msg: 'Unpublished screenshare track',
                        category: 'room',
                        kind: track.kind
                      })
                    })
              }
            })
          }
        }
      }
      if (currScreenShareVideoRef)
        currScreenShareVideoRef.srcObject = null;
      if (currScreenShareAudioRef)
        currScreenShareAudioRef.srcObject = null;
    }
  // eslint-disable-next-line
  }, [screenShareStream])

  useEffect(() => {
    if (!screenShareOpen && screenShareStream) {
      setScreenShareTrack(null);
    }
  // eslint-disable-next-line
  }, [screenShareOpen])

  useEffect(() => {
    if (screenShareVideoRef && screenShareVideoRef.current)
      screenShareVideoRef.current.srcObject = participantVideoStream;
    if (screenShareAudioRef && screenShareAudioRef.current)
        screenShareAudioRef.current.srcObject = participantAudioStream;
  }, [participantVideoStream, participantAudioStream])


  const onGotStream = (stream) => {
    setLocalAvStream(stream)
  }

  const onHideDeviceTest = () => {
    setShowDeviceTesting(false);
  }

  const handleFetchTokenError = (res, dataToSend) => {
    showRetryDialog('Could not get room details', false);
    sentryManager.captureMessage(
      'Could not fetch token - status: ' + res.status +
      ' Data: ' + JSON.stringify(dataToSend)
    );
    return false;
  }

  const showRetryDialog = (titleText, haveToken) => {
    setIsInitialConnect(false)
    setRetryDialogTitle(titleText)
    setRetryDialogText(`Try again or cancel to go back to your 
      Appointments Page.`);
    setOnRetryDiaglogYes(() => {
      return () => {
        setIsInitialConnect(true);
        setIsRetryDialogOpen(false);
        if (!haveToken)
          setTryGettingTokenAgain({try: true});
        else
          setTryRoomConnAgain({try: true});
      }
    })
    setOnRetryDiaglogNo(() => {
      return () => {
        setIsRetryDialogOpen(false);
        backToHome({});
      }
    })
    setIsRetryDialogOpen(true);
  }

  const onConnectToRoom = () => {
    setIsInitialConnect(false)
    setWaitingMsg(
      `Doctor ${doctorName} has been notified about your check in and will 
      join you soon. Please wait and don’t leave this screen`
    )
    addRoomEvents();
  }

  const addRoomEvents = () => {
    const room = twilioRoom.current;
    // add for already connected participants.
    room.participants.forEach(participantConnected);
    // listen for new participants.
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    room.on('disconnected', roomDisconnected);
    room.on('reconnecting', roomReconnecting);
    room.on('reconnected', roomReconnected);
  }

  const participantConnected = (participant) => {
    participantsInRoom.current += 1;
    sentryManager.setBreadcrumb({
      msg: 'Participant connected - Identity: ' + participant.identity,
      category: 'room',
      data: {
        noOfParticipants: participantsInRoom.current,
      }
    })
    callDurationMs.current = Date.now();
    setParticipantVideoMute(true);
    setParticipantAudioMute(true);
    setWaitingForDoctor(false);
    participant.on('trackSubscribed', track => addTrackToView(track, false));
    participant.on('trackUnsubscribed', track => removeTrackFromView(track));

    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        addTrackToView(publication.track, false);
      }
    });
  }

  const participantDisconnected = (participant) => {
    participantsInRoom.current -= 1;
    sentryManager.setBreadcrumb({
      msg: 'Participant disconnected - Identity: ' + participant.identity,
      category: 'room',
      data: {
        noOfParticipants: participantsInRoom.current,
      }
    })
    if (participantsInRoom.current === 1) {
      participantDisconnTimeout.current = setTimeout(() => {
        onDoctorDisconnectTasks();
      }, 3000)
    }
  }

  const onDoctorDisconnectTasks = () => {
    stopLocalTracks();
    if (!isAlertDialoagOpen) {
      setAlertDialogTitle(
        additionalDetails.isMr ?
          'Video Call Ended' :
          'Consulation Ended'
      );
      setAlertDialogText(`${'Dr ' + doctorName || 'Doctor'} has disconnected`);
      setOnAlertDiaglogClick(() => {
        return () => {
          setIsAlertDialoagOpen(false);
          backToHome({connectedToRoom: true});
        }
      });
      setIsAlertDialoagOpen(true);
    }
    sentryManager.setBreadcrumb({
      msg: 'Doctor Disconnected',
      category: 'room',
    })

    autoLeaveRoomTimeout.current = setTimeout(() => {
      sentryManager.setBreadcrumb({
        msg: 'Auto left room after doctor disconnected',
        category: 'room',
        data: {
          noOfParticipants: participantsInRoom.current,
        }
      })
      setIsAlertDialoagOpen(false);
      backToHome({connectedToRoom: true});
    }, 2000)
  }

  const roomDisconnected = (room, error) => {
    stopLocalTracks();
    sentryManager.setBreadcrumb({
      msg: 'Room disconnected',
      category: 'room',
      data: {
        error: error ? error.toString() : null,
      }
    })
    if (!manualLeave) {
      if (!error || error.code !== 53118) {
        setAlertDialogText('You have been disconnected from the room.');
        setAlertDialogTitle('Disconnected.');
      } else if (error.code === 53118) {
        // room completed
        clearInterval(participantDisconnTimeout.current)
        clearInterval(autoLeaveRoomTimeout.current);
        setAlertDialogText(
          additionalDetails.isMr ?
            `Your video call with ${'Dr ' + doctorName || 'doctor'} is 
          completed.` :
            `Your consultation with ${'Dr ' + doctorName || 'doctor'} is 
          completed. You will receive your e-prescription shortly.`
        );
        setAlertDialogTitle(
          additionalDetails.isMr ?
            'Video Call Ended' :
            'Consulation Ended'
        );
      }
      setOnAlertDiaglogClick(()=> {
        return () => {
          setIsAlertDialoagOpen(false);
          backToHome({
            connectedToRoom: true,
            roomEnded: true,
          });
        }
      });
  
      setIsAlertDialoagOpen(true);
    } else {
      console.log('Left Manually');
    }
  }


  const roomReconnecting = (room, error) => {
    setSnackbarOpen(false);
    setSnackbarSeverity('error');
    setSnackbarText('Reconnecting to room...');
    setSnackbarOpen(true);

    if (!error) {
      sentryManager.captureMessage(
        'Reconnecting to twilio room'
      );
      return;
    }

    if (error) {
      if (error.code === 53001) {
        sentryManager.captureMessage(
          'Reconnecting due to signaling connection:  ' + error.message
        );
      } else if (error.code === 53405) {
        sentryManager.captureMessage(
          'Reconnecting due to media connection: ', error.message
        );
      }
    }
  }

  const roomReconnected = () => {
    setSnackbarOpen(false);
    setSnackbarSeverity('success');
    setSnackbarText('Reconnected to room');
    setSnackbarHideDuration(5000);
    setSnackbarOpen(true);
    sentryManager.captureMessage(
      'Successfully reconnected to the room '
    )
  }


  const attachLocalTracks = () => {
    const room = twilioRoom.current;
    const localTracks = room.localParticipant.tracks;
    localTracks.forEach(localTrack => {
      addTrackToView(localTrack.track, true);
      // addTrackToView(localTrack.track);
    })
  }

  const addTrackToView = (track, isOwnTrack = false) => {
    let currAudioRef = participantAudioRef;
    let currVideoRef = participantVideoRef;

    if (isOwnTrack) {
      currAudioRef = ownAudioRef;
      currVideoRef = ownVideoRef;
      sentryManager.setBreadcrumb({
        msg: 'Adding local tracks to view',
        category: 'room',
      })
    } else {
      setWaitingForDoctor(false);
    }

    if (track.kind === 'audio') {
      currAudioRef.current.srcObject = track.attach().srcObject;
      if (!isOwnTrack) {
        sentryManager.setBreadcrumb({
          msg: 'Doctor audio track received but not started yet',
          category: 'room',
        })
        setParticipantAudioStream(currAudioRef.current.srcObject)
        const audioSentryLog = setTimeout(() => {
          sentryManager.captureMessage(
            'Got audio stream but not enough to start playback'
          )
        }, 4000)
        track.on('started', () => {
          clearInterval(audioSentryLog);
          setParticipantAudioMute(false);
          sentryManager.setBreadcrumb({
            msg: 'Audio track of doctor started',
            category: 'room',
          })
        })
        track.on('disabled', () => {
          setParticipantAudioMute(true);
          sentryManager.setBreadcrumb({
            msg: 'Doctor audio disabled',
            category: 'room',
          })
        })
        track.on('enabled', () => {
          setParticipantAudioMute(false);
          sentryManager.setBreadcrumb({
            msg: 'Doctor audio enabled',
            category: 'room',
          })
        })
      }
    }
    else if (track.kind === 'video') {
      currVideoRef.current.srcObject = track.attach().srcObject;
      if (!isOwnTrack) {
        sentryManager.setBreadcrumb({
          msg: 'Doctor audio track received but not started yet',
          category: 'room',
        })
        setParticipantVideoStream(currVideoRef.current.srcObject);
        const videoSentryLog = setTimeout(() => {
          sentryManager.captureMessage(
            'Got video stream but not enough to start playback'
          )
        }, 5000)
        track.on('started', () => {
          clearInterval(videoSentryLog);
          setParticipantVideoMute(false);
          sentryManager.setBreadcrumb({
            msg: 'Video track of doctor started',
            category: 'room',
          })
        })
        track.on('disabled', () => {
          setParticipantVideoMute(true);
          sentryManager.setBreadcrumb({
            msg: 'Doctor video disabled',
            category: 'room',
          })
        })
        track.on('enabled', () => {
          setParticipantVideoMute(false);
          sentryManager.setBreadcrumb({
            msg: 'Doctor video enabled',
            category: 'room',
          })
        })
      }
    }
  }

  const removeTrackFromView = (track) => {
    if (track.kind === 'audio') {
      setParticipantAudioMute(true)
      sentryManager.setBreadcrumb({
        msg: 'Doctor audio removed',
        category: 'room',
      })
    } else if (track.kind === 'video') {
      setParticipantVideoMute(true);
      sentryManager.setBreadcrumb({
        msg: 'Doctor video removed',
        category: 'room',
      })
    }
  }

  const leaveRoom = () => {
    setManualLeave(true);
    setConfirmDialogOpen(false);
    if (twilioRoom && twilioRoom.current) {
      sentryManager.setBreadcrumb({
        msg: 'Patient leaving room',
        category: 'room',
      })
      if (twilioRoom.current.localParticipant) {
        const localTracks = twilioRoom.current.localParticipant.tracks;
        if (localTracks) {
          localTracks.forEach(localTrack => {
            if (localTrack.kind !== 'data')
              localTrack.track.stop()
          })
          sentryManager.setBreadcrumb({
            msg: 'Stopped all local tracks before leaving',
            category: 'room',
          })
        }
        if (twilioRoom.current.state !== 'disconnected')
          twilioRoom.current.disconnect();
        twilioRoom.current = null;
      }
      backToHome({connectedToRoom: true});
    } else {
      sentryManager.captureMessage(`Patient clicked end consultation but 
        was not connected to the room`);
      backToHome({});
    }
  }

  const confirmLeaveRoom = (event) => {
    if (additionalDetails.isMr)
      setConfirmDialogText('Are you sure you want to end this Video call?')
    else
      setConfirmDialogText('Are you sure you want to end this consultation?');
    setConfirmDialogOpen(true)
  }

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setConfirmDialogText('')
  }

  const updateFacingMode = () => {
    if (facingMode.current === 'user') {
      facingMode.current = 'environment';
    }
    else {
      facingMode.current = 'user'
    }
    sentryManager.setBreadcrumb({
      msg: 'Patient video facing mode: ' + facingMode.current,
      category: 'room',
    })
  }

  const stopLocalTracks = () => {
    if (twilioRoom.current && twilioRoom.current.localParticipant) {
      const localTracks = twilioRoom.current.localParticipant.tracks
      if (localTracks) {
        localTracks.forEach(localTrack => {
          const track = localTrack.track;
          track.stop();
          twilioRoom.current.localParticipant.unpublishTrack(track);
        })
        sentryManager.setBreadcrumb({
          msg: 'Patient local tracks stopped',
          category: 'room',
        })
      }
    }
  }

  const stopLocalVideoTracks = () => {
    if (twilioRoom.current && twilioRoom.current.localParticipant) {
      const localTracks = twilioRoom.current.localParticipant.videoTracks;
      if (localTracks) {
        localTracks.forEach(localTrack => {
          const track = localTrack.track;
          if (track.name !== 'screenshare_video') {
            track.stop();
            twilioRoom.current.localParticipant.unpublishTrack(track);
          }
        })
        sentryManager.setBreadcrumb({
          msg: 'Patient video tracks stopped',
          category: 'room',
        })
      }
    }
  }

  // TODO: remove the disable linter once flip camera is working on Apps.
  // eslint-disable-next-line
  const flipCamera = (event) => {
    if (!isVideoDisabled) {
      updateFacingMode();
      stopLocalVideoTracks();
      // TODO add frameRate and video height and width once flip is working on 
      // iOS and Android.
      VIDEO.createLocalVideoTrack({
        facingMode: {
          ideal: facingMode.current
        }
      })
      .then((newLocalVideoTrack)=> {
        if (twilioRoom.current && twilioRoom.current.localParticipant) {
          twilioRoom.current.localParticipant.publishTrack(newLocalVideoTrack)
            .then(() => {
              sentryManager.setBreadcrumb({
                msg: 'Patient flipped camera',
                category: 'room',
              })
              attachLocalTracks()
            })
        }
      }).catch(err => {
        console.log('Cannot flip camera ', err);
        sentryManager.captureMessage(
          'Cannot flip camera: ' + JSON.stringify(err)
        );
      })
    }
  }

  const toggleMute = (event) => {
    sentryManager.setBreadcrumb({
      msg: 'Toggle audio clicked',
      category: 'room',
    })
    if (twilioRoom.current && twilioRoom.current.localParticipant) {
      sentryManager.setBreadcrumb({
        msg: 'Changing audio state',
        category: 'room',
      })
      const localTracks = twilioRoom.current.localParticipant.audioTracks;
      if (localTracks) {
        localTracks.forEach(localTrack => {
          const track = localTrack.track;
          if (track.name !== 'screenshare_audio') {
            if (track.isEnabled)
              track.disable();
            else
              track.enable();
            sentryManager.setBreadcrumb({
              msg: 'Patient audio mute: ' + !track.isEnabled,
              category: 'room',
            })
            setAudioMute(!track.isEnabled);
          }
        })
      }
    }
  }

  const toggleVideo = (event) => {
    sentryManager.setBreadcrumb({
      msg: 'Toggle video clicked',
      category: 'room',
    })
    if (twilioRoom.current && twilioRoom.current.localParticipant) {
      sentryManager.setBreadcrumb({
        msg: 'Changing video state',
        category: 'room',
      })
      const localTracks = twilioRoom.current.localParticipant.videoTracks;
      if (localTracks) {
        localTracks.forEach(localTrack => {
          const track = localTrack.track;
          if (track.name !== 'screenshare_video') {
            if (track.isEnabled)
              track.disable();
            else
              track.enable();
            sentryManager.setBreadcrumb({
              msg: 'Patient video disabled: ' + !track.isEnabled,
              category: 'room',
            })
            setVideoDisabled(!track.isEnabled);
          }
        })
      }
    }
  }

  const handleSnackbarClose = (event, reason) => {
    if (snackbarSeverity === 'error' &&  reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false)
    setSnackbarHideDuration(null);
  }

  const toggleScreenShare = (event) => {
    if (!screenShareOpen) {
      navigator.mediaDevices.getDisplayMedia({
        video: {
          frameRate: {
            ideal: 15
          },
          height: {
            ideal: 480,
          },
          width: {
            ideal: 640
          }
        }
      })
        .then(stream => {
          sentryManager.setBreadcrumb({
            msg: 'Got screenshare track',
            category: 'room',
          })
          setScreenShareTrack(stream);
        })
        .catch(err => {
          sentryManager.captureMessage(
            'Error getting screenshare track: ' + err
          )
          setScreenShareOpen(false);
          setScreenShareTrack(null);
        })
    } else {
      setScreenShareTrack(null);
    }
    setScreenShareOpen(prevVal => !prevVal)
  }

  if (showDeviceTesting) {
    return (
      <DeviceTestingPage onGotStream={onGotStream} 
        onHideDeviceTest={onHideDeviceTest}
        isStandalone={false}
        isReconnect={isReconnect}
      />
    )
  }
  return (
    <div className="video-container">
      <div className={'focus-area'}>
        {
          waitingForDoctor &&
          <div className={'wait-bar'}>
            {
              isInitalConnect &&
              <CircularProgress className={'progress'} size={64} />
            }
            <Typography className={'wait-text'} variant="subtitle1">
              {waitingMsg}
            </Typography>
          </div>
        }
        <Slide
          direction="up"
          in={(Boolean(screenShareStream) && screenShareStream.active &&
            screenShareOpen)}
        >

          <MuiAlert
            variant="filled"
            severity="info"
            elevation={6}
            className={'consultation-room-share-alert'}
            action={
              <Button size="small"
                variant="outlined"
                className="share-screen alert action"
                onClick={toggleScreenShare}
              >
                Stop Sharing
              </Button>
            }
          >
            You are sharing your screen
          </MuiAlert>
        </Slide>

        <div
          className={'focused-video ' +
            ((screenShareStream && screenShareStream.active &&
              screenShareOpen) ?
              'display none ' : ' ')
          }
        >
          <ParticipantVideo 
            participantVideoMute={participantVideoMute}
            participantAudioMute={participantAudioMute}
            doctorName={doctorName}
            waitingForDoctor={waitingForDoctor}
            participantVideoRef={participantVideoRef}
            participantAudioRef={participantAudioRef}
            videoSize={participantVideoSize.large}
          >
          </ParticipantVideo>
        </div>
      </div>
      <div className="video-controls-wrapper">
        <div
          className={'video-controls'}
        >
          <div className="call-end-container">
            <IconButton
              className={'call-end'}
              onClick={confirmLeaveRoom}
            >
              <CallEndIcon></CallEndIcon>
            </IconButton>
          </div>

          <IconButton disabled={isInitalConnect}
            className={'flip-camera'}
            onClick={flipCamera}
          >
            <FlipCameraIosIcon></FlipCameraIosIcon>
          </IconButton>

          <IconButton
            disabled={isInitalConnect}
            className={'video-off ' + (isVideoDisabled ? 'active' : '')}
            onClick={toggleVideo}>
            <VideocamOffIcon></VideocamOffIcon>
          </IconButton>
          <IconButton
            disabled={isInitalConnect}
            className={'mic-off ' + (isAudioMute ? 'active' : '')}
            onClick={toggleMute}
          >
            <MicOffIcon></MicOffIcon>
          </IconButton>
          {
            additionalDetails.isMr && isDesktop &&
            <IconButton
              disabled={isInitalConnect}
              className={'share-screen ' + (screenShareOpen ? 'active' : '')}
              onClick={toggleScreenShare}
            >
              {
                !screenShareOpen &&
                <ScreenShareOutlinedIcon />
              }
              {
                screenShareOpen &&
                <StopScreenShareOutlinedIcon />
              }
            </IconButton>
          }
        </div>
      </div>
      <Draggable>
        <div
          className="own-video"
        >
          <div>
            <video
              className={'own-video-elt'}
              ref={ownVideoRef}
              autoPlay={true}
              playsInline={true}
              muted={true}
            ></video>
            <audio
              ref={ownAudioRef}
              muted={true}
            ></audio>
          </div>
          <div
            className={
              'screen-share-container ' +
              (!(screenShareStream && screenShareStream.active &&
                screenShareOpen && participantsInRoom.current > 1) ?
                'display none ' : ' ')
            }
          >
            <div className="video-title text center">
                Doctor
            </div>
            <ParticipantVideo
              participantVideoMute={participantVideoMute}
              participantAudioMute={participantAudioMute}
              doctorName={doctorName}
              waitingForDoctor={waitingForDoctor}
              participantVideoRef={screenShareVideoRef}
              participantAudioRef={screenShareAudioRef}
              videoSize={participantVideoSize.small}
            ></ParticipantVideo>
          </div>
        </div>
      </Draggable>

      <Dialog
        open={isConfirmationDialogOpen}
      >
        <DialogTitle>
          {
            additionalDetails.isMr &&
            "End Video Call?"
          }
          {
            !additionalDetails.isMr &&
            "End Consultation?"
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={leaveRoom} color="primary">
            Yes
          </Button>
          <Button onClick={closeConfirmDialog} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isRetryDialogOpen}
      >
        <DialogTitle>{retryDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {retryDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onRetryDiaglogYes} color="primary">
            Try again
          </Button>
          <Button onClick={onRetryDiaglogNo} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={isAlertDialoagOpen}
      >
        <DialogTitle>{alertDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {alertDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onAlertDiaglogClick} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={snackbarHideDuration}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarText}
        </Alert>
      </Snackbar>

    </div>
  )
}


export default ConsultationRoom;
