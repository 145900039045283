import MicTest from './MicTest';
import constants from '../constants';

class AudioTrackChecker {
    constructor() {
        if (!AudioTrackChecker.audioTrackChecker) {
            AudioTrackChecker.audioTrackChecker = this;
        }
        this.messagesArr = {
            error: [],
            success: [],
            warning: [],
            info: []
        }
        this.micTestResolver = null;
        this.rejectTimeout = null;
        this.isMounted = {state: true};
        this.audioTest = {
            done: () => {
                if (this.micTestResolver && this.isMounted.state) {
                    clearInterval(this.rejectTimeout);
                    this.micTestResolver();
                }
            },
            reportError: (err) => {
                this.messagesArr.error = 
                    this.messagesArr.error.concat([err])
            },
            reportSuccess: (succ) => {
                this.messagesArr.success = 
                    this.messagesArr.success.concat([succ])
            },
            reportInfo: (info) => {
                this.messagesArr.info = 
                    this.messagesArr.info.concat([info])
            },
            reportWarning: (info) => {
                this.messagesArr.warning =
                    this.messagesArr.warning.concat([info])
            }

        }
        this.micTest = new MicTest(this.audioTest);
        return AudioTrackChecker.audioTrackChecker;
    }

    startTest(stream, isStandAlone, isMounted) {
        let endTimeout = 4000;
        this.isMounted = isMounted
        if (isStandAlone)
            endTimeout = 5000;
        return new Promise((resolve, reject) => {
            this.micTestResolver = resolve;
            this.micTest.run(stream)
            setTimeout(()=> {
                clearInterval(this.rejectTimeout);
                this.stopTest()
            }, endTimeout)
            this.rejectTimeout = setTimeout(() => {
                if (this.isMounted.state)
                    reject({name: 'timeout', message: 'Video Check timeout'})
            }, 1000 * constants.deviceTestTimeoutSec)
        })
    }

    stopTest() {
        this.micTest.onStopCollectingAudio();
    }
}

const audioTrackChecker = new AudioTrackChecker();

export default audioTrackChecker;
