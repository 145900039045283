import '../styles/ParticipantVideo.css';
import React from 'react';
import {
  Typography,
  Avatar,
} from '@material-ui/core'
import MicOffIcon from '@material-ui/icons/MicOff';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import dataManager from '../utils/DataManager';

const participantVideoSize = dataManager.getParticpantVideoSize();

function ParticipantVideo(props) {

  const {
    participantVideoMute,
    participantAudioMute,
    doctorName,
    waitingForDoctor,
    participantVideoRef,
    participantAudioRef,
    videoSize,
  } = props;

  return (
    <div
      className={
        'participant-video ' + (participantVideoMute ? 'blur ' : ' ') +
        (videoSize === participantVideoSize.small ? 'small ' : ' ' ) +
        (videoSize === participantVideoSize.large ? 'large ' : ' ' )
      }
    >
      {
        participantAudioMute &&
        <div
          className={
            'audio-indicator ' +
            (videoSize === participantVideoSize.small ? 'small ' : ' ')
          }
        >
          <Avatar className={'audio-avatar'}>
            <MicOffIcon />
          </Avatar>
        </div>
      }
      {
        participantVideoMute &&
        <div
          className={
            'video-indicator ' +
            (videoSize === participantVideoSize.small ? 'small ' : ' ')
          }
        >
          <Avatar className={'video-avatar'}>
            <AccountCircleIcon />
          </Avatar>
          {
            videoSize !== participantVideoSize.small &&
            <Typography variant="caption">Doctor {doctorName}</Typography>
          }
        </div>
      }
      {
        !waitingForDoctor &&
        <>
          <video
            ref={participantVideoRef}
            autoPlay={true}
            playsInline={true}
          ></video>
          <audio
            ref={participantAudioRef}
            autoPlay={true}
          ></audio>
        </>
      }
    </div>
  )

}

export default ParticipantVideo;
