import { useState, useEffect } from 'react';
import {
    useLocation
} from 'react-router-dom';
import { createBrowserHistory } from 'history'

function useShowCallRatingDialog() {

    const location = useLocation();
    const history = createBrowserHistory();

    const [showCallRating, setShowCallRating] = useState(false);
    const [roomDurationMs, setRoomDurationMs] = useState(0);
    const [roomAlreadyEnded, setRoomAlreadyEnded] = useState(false);

    useEffect(() => {
        const state = location.state || null;
        if (state && state.from === 'fromLeavingRoom') {
            setShowCallRating(true);
            setRoomDurationMs(state.roomDurationMs);
            setRoomAlreadyEnded(state.roomAlreadyEnded);
            state.from = null;
            history.replace(location.pathname, state);
        }
        return () => {
            setShowCallRating(false)
            setRoomDurationMs(0);
            setRoomAlreadyEnded(false);
        }
    // eslint-disable-next-line
    }, [location])

    return [showCallRating, roomDurationMs, roomAlreadyEnded];
}

export default useShowCallRatingDialog;
